const {BellIcon} = require("@heroicons/vue/outline")
const HomeIcon = require("@heroicons/vue/outline")
const KeyIcon= require("@heroicons/vue/outline")
const MenuIcon = require("@heroicons/vue/outline")
const UserIcon = require("@heroicons/vue/outline")
let icons = [BellIcon, HomeIcon, KeyIcon, MenuIcon, UserIcon];
module.exports = {
    patientNavigation: [
        { name: 'Home', href: '#', icon: icons[1] },
        { name: 'History', href: '#', icon: icons[3] },
        { name: 'Profile', href: '#', icon: icons[5] },
        { name: 'Password', href: '#', icon: icons[2] },
        { name: 'Alerts', href: '#', icon: icons[0] },
      ],
      LOGIN_TYPE_ADMIN: "admin",
      LOGIN_TYPE_PATIENT: "patient",
      LOGIN_TYPE_PROVIDER: "provider",
      DEFAULT_PAYER_PATH: "m/0/0/0",
      ADMIN_OWNER_PATH: "m/0/0/1",
      ADMIN_PAYMENT_PATH: "m/0/0/0",
      PROVIDER_OWNER_PATH: "m/0/1/0",
      PROVIDER_PAYMENT_PATH: "m/0/0/0",
      PATIENT_OWNER_PATH: "m/0/1/1",
      PATIENT_PAYMENT_PATH: "m/0/0/0",
      TEST_NETWORK: "test",
      LIVE_NETWORK: "live",
      TEST_PAYMENT_PRIVATE_KEY: "cRLAbpQsoYeMrv1BrLsbefjh6wtLWLNDciAjYWYxzTaG43W9resz",
      TEST_ADMIN_SEED: "analyst priority teach picnic remove genuine sort tag noodle dumb decorate cram",
      TEST_ADMIN_PRIVATE_KEY: "cRLAbpQsoYeMrv1BrLsbefjh6wtLWLNDciAjYWYxzTaG43W9resz",
      TEST_ADMIN_OWNER_PRIVATE_KEY: "cNsp2LMM3b5Eptx2GiGwyq6Q1KJw14Z7zfzfF7QGB7UR3Kjh3Ui8",      

}