<template>
    <div class="relative">
     <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div class=" p-0 md:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-2xl md:overflow-hidden">
            <div class="absolute inset-0">
              <img class="h-full w-full object-cover" src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops" />
              <div class="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
            </div>
            <div class="relative px-4 py-16 sm:px-6 md:py-24 lg:py-32 lg:px-8">
              <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span class="block text-white text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-4xl">Take control of your</span>
                <span class="block text-indigo-200">Vaccination Records</span>
              </h1>
              <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                
              </p>
              <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                  With VXLITE™<br/>
               you can now store your Vaccination Records securely on the blockchain even without a doctor. 
              </p>
              <div class="mt-10 max-w-sm mx-auto md:max-w-none md:flex md:justify-center">
                <div class=" flex-grow space-y-4 md:space-y-0 md:mx-auto md:inline-grid md:gap-5">
                 <button @click="clicked" class="w-full max-w-xl md:mx-auto items-center justify-center px-4 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-purple-700 hover:to-indigo-700">
                    Buy Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    methods:{
        clicked(){
            this.$emit('clicked');
        }
    }
}
</script>

<style lang="scss" scoped>

</style>