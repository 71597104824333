<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'blue-gray': colors.blueGray,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<template>
  <div class="h-screen flex bg-white overflow-hidden text-left">
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-blue-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-4">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="mobileMenuOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="pt-5 pb-4">
              <div class="flex-shrink-0 flex items-center px-4">
                  <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=blue&shade=600" alt="Workflow" /> -->
                  <VXPCircleLogo />
              </div>
              
              <nav aria-label="Sidebar" class="mt-5">
                <div class="px-2 space-y-1">
                  <button v-for="item in navigationItems" :key="item.name" @click="navigate(item.href)" class="group p-2 rounded-md flex items-center text-base font-medium text-blue-gray-600 hover:bg-blue-gray-50 hover:text-blue-gray-900">
                    <component :is="item.icon" class="mr-4 h-6 w-6 text-blue-gray-400 group-hover:text-blue-gray-500" aria-hidden="true" />
                    {{ item.name }}
                  </button>
                </div>
              </nav>
            </div>
            <div class="flex-shrink-0 flex border-t border-blue-gray-200 p-4">
              <slot name="topMenuUser">
              <a href="#" class="flex-shrink-0 group block">
                <div class="flex items-center">
                  <div>
                    <img class="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80" alt="" />
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-blue-gray-700 group-hover:text-blue-gray-900">
                      Lisa Marie
                    </p>
                    <p class="text-sm font-medium text-blue-gray-500 group-hover:text-blue-gray-700">
                      Account Settings
                    </p>
                  </div>
                </div>
              </a>
              </slot>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div :class="`hidden lg:flex lg:flex-shrink-0 ${sidebarClasses}`" >
      <div class="flex flex-col w-20">
        <div class="flex flex-col h-0 flex-1 overflow-y-auto" >
          <div class="flex-1 flex flex-col">
            <div class="flex-shrink-0  py-4 flex items-center justify-center">
              <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white" alt="Workflow" /> -->
              <div class=" w-16" ><VXPWhiteCircleLogo /></div>
            </div>
            <nav aria-label="Sidebar" class="py-6 flex flex-col items-center space-y-3">
              <a v-for="item in navigationItems" :key="item.name" :href="item.href" class="flex items-center p-4 rounded-lg text-white hover:bg-pink-700">
                <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">{{ item.name }}</span>
              </a>
            </nav>
          </div>
          <div class="flex-shrink-0 flex pb-5">
            <slot name="userSidebar">
            <a href="#" class="flex-shrink-0 w-full">
              <img class="block mx-auto h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80" alt="" />
              <div class="sr-only">
                <p>
                  Lisa Marie
                </p>
                <p>
                  Account settings
                </p>
              </div>
            </a>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
      <!-- Mobile top navigation -->
      <div class="lg:hidden">
        <div :class="`py-2 px-4 flex items-center justify-between sm:px-6 ${navigationClasses}`">
          <div>
            <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white" alt="Workflow" /> -->
            <VXPass></VXPass>
          </div>
          <div>
            <button type="button" class="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-transparent rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="mobileMenuOpen = true">
              <span class="sr-only">Open sidebar</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <main class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
          <!-- Breadcrumb -->
          <!-- <nav aria-label="Breadcrumb" class="bg-white border-b border-blue-gray-200 xl:hidden">
            <div class="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8">
              <a href="#" class="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-blue-gray-900">
                <ChevronLeftIcon class="h-5 w-5 text-blue-gray-400" aria-hidden="true" />
                <span>Settings</span>
              </a>
            </div>
          </nav> -->

          <div class="flex-1 flex xl:overflow-hidden">
            <!-- Secondary sidebar -->
            <nav v-if="showSecond" aria-label="Sections" class="hidden flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col">
              <div class="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
                <p class="text-lg font-medium text-blue-gray-900">Settings</p>
              </div>
              <div class="flex-1 min-h-0 overflow-y-auto">
                <a v-for="item in subNavigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50', 'flex p-6 border-b border-blue-gray-200']" :aria-current="item.current ? 'page' : undefined">
                  <component :is="item.icon" class="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400" aria-hidden="true" />
                  <div class="ml-3 text-sm">
                    <p class="font-medium text-blue-gray-900">{{ item.name }}</p>
                    <p class="mt-1 text-blue-gray-500">{{ item.description }}</p>
                  </div>
                </a>
              </div>
            </nav>

            <!-- Main content -->
            <div class="flex-1 max-h-screen xl:overflow-y-auto">
              <div class="max-w-5xl mx-auto p-1">
                <slot name="form">
                    <h1 class="text-3xl font-extrabold text-blue-gray-900">Account</h1>
                    <form class="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                        <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-blue-gray-900">Profile</h2>
                        <p class="mt-1 text-sm text-blue-gray-500">This information is <span class="text-red-600"> NOT </span> stored on the Blockchain. If you choose to save this information is</p>
                        </div>

                        <div class="sm:col-span-3">
                        <label for="first_name" class="block text-sm font-medium text-blue-gray-900">
                            First name
                        </label>
                        <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-3">
                        <label for="last_name" class="block text-sm font-medium text-blue-gray-900">
                            Last name
                        </label>
                        <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-6">
                        <label for="username" class="block text-sm font-medium text-blue-gray-900">
                            Username
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-blue-gray-50 text-blue-gray-500 sm:text-sm">
                            workcation.com/
                            </span>
                            <input type="text" name="username" id="username" autocomplete="username" value="lisamarie" class="flex-1 block w-full min-w-0 border-blue-gray-300 rounded-none rounded-r-md text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                        </div>

                        <div class="sm:col-span-6">
                        <label for="photo" class="block text-sm font-medium text-blue-gray-900">
                            Photo
                        </label>
                        <div class="mt-1 flex items-center">
                            <img class="inline-block h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80" alt="" />
                            <div class="ml-4 flex">
                            <div class="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                <label for="user_photo" class="relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                                <span>Change</span>
                                <span class="sr-only"> user photo</span>
                                </label>
                                <input id="user_photo" name="user_photo" type="file" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" />
                            </div>
                            <button type="button" class="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">
                                Remove
                            </button>
                            </div>
                        </div>
                        </div>

                        <div class="sm:col-span-6">
                        <label for="description" class="block text-sm font-medium text-blue-gray-900">
                            Description
                        </label>
                        <div class="mt-1">
                            <textarea id="description" name="description" rows="4" class="block w-full border border-blue-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                        <p class="mt-3 text-sm text-blue-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                        </div>

                        <div class="sm:col-span-6">
                        <label for="url" class="block text-sm font-medium text-blue-gray-900">
                            URL
                        </label>
                        <input type="text" name="url" id="url" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                    </div>

                    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                        <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-blue-gray-900">Personal Information</h2>
                        <p class="mt-1 text-sm text-blue-gray-500">This information will be displayed publicly so be careful what you share.</p>
                        </div>

                        <div class="sm:col-span-3">
                        <label for="email_address" class="block text-sm font-medium text-blue-gray-900">
                            Email address
                        </label>
                        <input type="text" name="email_address" id="email_address" autocomplete="email" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-3">
                        <label for="phone_number" class="block text-sm font-medium text-blue-gray-900">
                            Phone number
                        </label>
                        <input type="text" name="phone_number" id="phone_number" autocomplete="tel" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-3">
                        <label for="country" class="block text-sm font-medium text-blue-gray-900">
                            Country
                        </label>
                        <input type="text" name="country" id="country" autocomplete="country" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-3">
                        <label for="language" class="block text-sm font-medium text-blue-gray-900">
                            Language
                        </label>
                        <input type="text" name="language" id="language" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <p class="text-sm text-blue-gray-500 sm:col-span-6">This account was created on <time datetime="2017-01-05T20:35:40">January 5, 2017, 8:35:40 PM</time>.</p>
                    </div>

                    <div class="pt-8 flex justify-end">
                        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Cancel
                        </button>
                        <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Save</button>
                    </div>
                    </form>
                </slot>
              </div>
              
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref} from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  BellIcon,
  BookmarkAltIcon,
  CashIcon,
  CogIcon,
  FireIcon,
  HomeIcon,
  InboxIcon,
  KeyIcon,
  MenuIcon,
  PhotographIcon,
  SearchCircleIcon,
  UserIcon,
  ViewGridAddIcon,
  XIcon,
} from '@heroicons/vue/outline'
import { ChevronLeftIcon } from '@heroicons/vue/solid'
import VXPass from '../../apps/common/svgs/VXPass.vue'
import VXPWhiteCircleLogo from '../../apps/common/svgs/VXPWhiteCircleLogo.vue'
import VXPCircleLogo from "./../../../components/apps/common/svgs/VXPCircleLogo.vue"

const navigation = [
  { name: 'Home', href: '#', icon: HomeIcon },
  { name: 'Trending', href: '#', icon: FireIcon },
  { name: 'Bookmarks', href: '#', icon: BookmarkAltIcon },
  { name: 'Messages', href: '#', icon: InboxIcon },
  { name: 'Profile', href: '#', icon: UserIcon },
]
const subNavigation = [
  {
    name: 'Account',
    description: 'Ullamcorper id at suspendisse nec id volutpat vestibulum enim. Interdum blandit.',
    href: '#',
    icon: CogIcon,
    current: true,
  },
  {
    name: 'Notifications',
    description: 'Enim, nullam mi vel et libero urna lectus enim. Et sed in maecenas tellus.',
    href: '#',
    icon: BellIcon,
    current: false,
  },
  {
    name: 'Security',
    description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
    href: '#',
    icon: KeyIcon,
    current: false,
  },
  {
    name: 'Appearance',
    description: 'Magna nulla id sed ornare ipsum eget. Massa eget porttitor suscipit consequat.',
    href: '#',
    icon: PhotographIcon,
    current: false,
  },
  {
    name: 'Billing',
    description: 'Orci aliquam arcu egestas turpis cursus. Lectus faucibus netus dui auctor mauris.',
    href: '#',
    icon: CashIcon,
    current: false,
  },
  {
    name: 'Integrations',
    description: 'Nisi, elit volutpat odio urna quis arcu faucibus dui. Mauris adipiscing pellentesque.',
    href: '#',
    icon: ViewGridAddIcon,
    current: false,
  },
  {
    name: 'Additional Resources',
    description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
    href: '#',
    icon: SearchCircleIcon,
    current: false,
  },
]

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    VXPass,
    VXPWhiteCircleLogo,
    VXPCircleLogo,
  },
  setup(props) {
    const mobileMenuOpen = ref(false);
    console.log("Props", props.mainNavItems);
    let navItems  = new Array(props.mainNavItems);
    let navClasses = new String(props.navClassOverride);
    let sideClasses = new String(props.sidebarClassOverride);
    console.log("Main Nav Classes", navClasses);
    console.log("Sidebar Nav Classes", props.sidebarClassOverrride);
    const showSecond = ref(false);
    if(props.showSecondMenu === null){showSecond.value = false;}
    else{showSecond.value = props.showSecondMenu === true ? true: false}
    return {
      navItems,
      navClasses,
      sideClasses,
      navigation,
      subNavigation,
      mobileMenuOpen,
      showSecond,
    }
  },
  computed:{
      navigationItems(){
          if(this.navItems.length > 0 && this.navItems[0] !== undefined){return this.navItems[0]}
          else {return this.navigation}
      },
      navigationClasses(){
        console.log(this.navClasses)
        if(this.navClasses){return this.navClasses}
        else return "bg-blue-500"
      },
      sidebarClasses(){
        console.log(this.sideClasses)
        if(this.sideClasses){return this.sideClasses}
        else return "bg-blue-500"
      }
  },
  methods:{
    navigate(relative_path){
      this.$router.push(relative_path);
    }
  },
  props:{
      mainNavItems: Array,
      showSecondMenu: Boolean,
      navClassOverride: String,
      sidebarClassOverride: String
  }
}
</script>