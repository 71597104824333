<template>
    <LoggedIn>
        <template v-slot:welcomePanel >
            <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="bg-white p-6">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <div class="sm:flex sm:space-x-5">
                        <div class="flex-shrink-0">
                            <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="user.imageUrl" alt="" /> -->
                        </div>
                        <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p class="text-sm font-medium text-gray-600">Welcome back,</p>
                            <p class="text-xl font-bold text-gray-900 sm:text-2xl">{{ store.state.providerAddress }}</p>
                            <p class="text-sm font-medium text-gray-600">{{  }}</p>
                        </div>
                    </div>
                    <div class="mt-5 flex justify-center sm:mt-0">
                    <button @click="alert('Hook Me Up')" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        View Keys
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </template>
        <template v-slot:actionsPanel>
            <ProviderCredentialList />
        </template>
        
    </LoggedIn>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore} from "vuex"
import ProviderCredentialList from "../../../components/apps/common/credentials/List.vue"
import LoggedIn from "./layouts/LoggedIn.vue"

export default {
    setup () {
        let store = useStore();
        const state = reactive({
            count: 0,
        })
    
        return {
            store,
            ...toRefs(state),
        }
    },
    components:{LoggedIn, ProviderCredentialList}
}
</script>

<style lang="scss" scoped>

</style>