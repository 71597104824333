<template>
    <div class='m-2 '> 
        <button type="button" class="m-1 p-1 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
        {{Language.SET_PATIENT_ADDRESS}}
    </button>
    
</div>
<div class='pt-2 flex-grow m-2 '>
    <input v-model="patientAddress" class='min-w-sm w-full rounded shadow' />
    
</div>
<div class="flex-grow m-2">
    <button @click="props.createPatientFunction" type="button" class="m-1 p-1  w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ring-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
        Create Patient
    </button>
</div>
</template>

<script>
import Language from "./../../../constants/language/english.js";
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    props:["createPatientFunction"]
}
</script>

<style lang="scss" scoped>

</style>