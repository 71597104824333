<template>
    <div>
        <ProviderCredentialList />
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import ProviderCredentialList from "./../../../../components/apps/common/credentials/List.vue"

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    components:{ProviderCredentialList}
}
</script>

<style lang="scss" scoped>

</style>