<template>
    <div action="#" method="POST" class="space-y-6">
        <div>
            <label for="seed" class="block text-sm font-medium text-gray-700">
            {{Language.SEED_PHRASE}}
            </label>
            <div class="mt-1">
            <input v-model="providerSeed"  id="seed" name="seed" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
        <!-- <div class='text-xl font-thin tracking-tight'> or </div>
        <div class="space-y-1">
            <label for="password" class="block text-sm font-medium text-gray-700">
            {{Language.PRIVATE_KEY}}
            </label>
            <div class="mt-1">
            <input id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div> -->

        <div class="flex items-center justify-between">
            <div class="flex items-center">
            <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
            <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                {{Language.REMEMBER_ME_TEXT}}
            </label>
            </div>

            <div class="text-sm">
            <button @click="showRegister" class="font-medium text-indigo-600 hover:text-indigo-500">
                {{Language.SIGN_UP_TEXT}}
            </button>
            </div>
        </div>

        <div>
            <button @click="login(providerSeed, loginType)" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{Language.SIGN_IN_TEXT}}
            </button>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue';
import {useStore} from "vuex";
import Language from "../../../constants/language/english.js";

export default {
    setup(props) {
        let _store = useStore();
        let _providerSeed = _store.providerSeed;        
        let loginProp = ref(props.loginType);
        const clickRegister = () => {
             props.showRegister()
        }
        
         const state = reactive({
            providerSeed: _providerSeed,
        });
        return {
            Language,
            loginProp,
            clickRegister,
            ...toRefs(state),
        }
    },
    methods: {
        async login(){
            let _seed = this.providerSeed.trim();
            let _loginType =  this.loginType;
            console.log("LoginType is :", this.loginType);
            let params = {_seed, _loginType};
            let response = await this.$store.dispatch("login", params);
            console.log(response);
            if(response instanceof Error){
                alert(response);
            }else{
                console.log("about to push to profile")
                this.$router.push('/apps/provider/profile')
            }
        }
    },
    props:["loginType", "showRegister"]
}
</script>
