<template>
    <div>
        <LandingPage loginRoute="/apps/provider/login" />
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import LandingPage from "./../../../../components/tailwind/pages/LandingHeroWithScreenshot.vue"

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    components: {LandingPage}
}
</script>

<style lang="scss" scoped>

</style>