<template>
    <div class="" :class="pageClasses">
        <slot name="content"></slot>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup (props) {
        const state = reactive({
            pageClasses: props.pageClasses,
        })
    
        return {
            ...toRefs(state),
        }
    },
    props:{
        pageClasses: String,
    }
}
</script>

<style lang="scss" scoped>

</style>