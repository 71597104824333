<template>
    <logged-in> 
        <template v-slot:content >
            <div class="bg-white rounded-xl shadow-xl h-full w-full m-2 p-2 justify-center">
                <!-- <div class="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                    <h3 class="text-2xl ml-2 pl-2 leading-6 font-medium text-gray-900">
                       {{Language.MY_ACCOUNT_TITLE}}
                    </h3>
                    <div class="mt-3 flex sm:mt-0 sm:ml-4 ">
                        <button @click="copy('all')" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                            Copy All 
                        </button>
                    </div>
                </div> -->
                
                <!-- <div class="p-3 m-3 text-sm">
                    <p class="p-3 m-3">{{Language.MY_ACCOUNT_EXPLANATION_1}}</p>
                    <p class="p-3 m-3">{{Language.MY_ACCOUNT_EXPLANATION_2}}</p>
                </div> -->
                <three-tier-with-callout>
                    <template v-slot:copyAll> 
                         <button @click="copy('all')" type="button" class=" pt-3 mt-3 block w-full text-center rounded-lg border border-transparent bg-green-400 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-green-600">
                            Copy All 
                        </button>
                    </template>
                    <template v-slot:buttons>
                        <div class="grid grid-cols-5  p-2 m-2 rounded  shadow-xl items-center ring-1 ring-indigo-200">
                            <div class='col-span-1'>{{Language.SEED}}</div>
                            <div class='col-span-3'><span class='text-xs max-w-sm '> {{seed}} </span></div>
                            <div class='col-span-1 flex-col'>
                                <button @click="showSeed = !showSeed" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                                    {{showSeed 
                                        ? Language.HIDE  
                                        : Language.SHOW}}
                                </button>
                                <button @click="copy('seed')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                                    {{Language.COPY }}
                                </button>
                            </div>
                        </div>
                        <div class='grid grid-cols-5  p-2 m-2 rounded  shadow-xl  items-center ring-1 ring-indigo-200'>
                            <div class='col-span-1'>{{Language.PRIVATE_KEY}}</div>
                            <div class='col-span-3 '>
                                <span class='text-xs max-w-sm break-all'> {{privateKey}} </span>
                                </div>
                            <div class='col-span-1 flex-col'>
                                <button @click="showPrivateKey = !showPrivateKey" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-indigo-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                                    {{showPrivateKey 
                                        ? Language.HIDE  
                                        : Language.SHOW}}
                                </button>
                                <button @click="copy('pk')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                                    {{Language.COPY }}
                                </button>
                            </div>
                        </div> 
                        <div class='grid grid-cols-5 p-2 m-2 rounded shadow-xl  items-center ring-1 ring-indigo-200'>
                            <div class='col-span-1'>{{Language.ADDRESS}}</div>
                            <div class='col-span-3 '>
                                <span class='text-xs max-w-sm break-all'> {{address}} </span>
                            </div>
                            <div class='col-span-1 flex-col'>
                                <button @click="showAddress = !showAddress" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-indigo-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                                    {{showAddress 
                                        ? Language.HIDE  
                                        : Language.SHOW}}
                                </button>
                                <button @click="copy('address')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                                    {{Language.COPY }}
                                </button>
                            </div>
                        </div>
                    </template>
                </three-tier-with-callout>
                
            </div>
             <input type="hidden" id="copy-input">
        </template>
    </logged-in>
</template>

<script>
import { reactive, toRefs } from 'vue'
import LoggedIn from './layouts/LoggedIn.vue'
import Language from "./../../../constants/language/english.js";
//import WithFeaturesList from '../../../components/tailwind/featureSections/WithFeaturesList.vue';
//import { InboxIcon, PhoneIcon } from '@heroicons/vue/outline'
import ThreeTierWithCallout from '../../../components/tailwind/pricing/ThreeTierWithCallout.vue';

export default {
    components: { LoggedIn, ThreeTierWithCallout},
    setup () {
        const state = reactive({
            count: 0,
            showSeed: false,
            showPrivateKey: false,
            showAddress: false
        })
    
        return {
            ...toRefs(state),
            Language
        }
    },
    methods:{
        copy(_type){
            let cpy = "";
             let _toCopy = document.querySelector('#copy-input')
             _toCopy.setAttribute('type', 'text')
            if(_type === 'all'){
                cpy = `Seed: ${this.$store.state.patientSeed} 
                Private Key: ${this.$store.state.patientPrivKey}
                Address: ${this.$store.state.patientAddress}`
            }else if(_type ==="seed"){cpy = this.$store.state.patientSeed}
            else if(_type ==="pk"){cpy = this.$store.state.patientPrivKey}
            else if(_type ==="address"){cpy = this.$store.state.patientAddress}
            console.log({cpy})
             _toCopy.setAttribute('type', 'text')
             _toCopy.value = cpy;
            _toCopy.select();
            try {
                document.execCommand('copy');
                 _toCopy.setAttribute('type', 'hidden')
          } catch (err) {
                alert('Oops, unable to copy');
          }
        }
    },
    computed:{
        privateKey(){
            if(this.showPrivateKey){return this.$store.state.patientPrivKey}
            else {return "*** " + Language.HIDDEN + "***"}
        },
        seed(){
            if(this.showSeed){return this.$store.state.patientSeed}
            else{return "*** " + Language.HIDDEN + " ***"}
        },
        address(){
             if(this.showAddress){return this.$store.state.patientAddress}
            else{return "*** " + Language.HIDDEN + " ***"}
        },
        firstSix(){
            if(this.$store.state.patientSeed !== ""){
                let wordarray = this.$store.state.patientSeed.split(" ");
                let six = wordarray.slice(0, 6)
                console.log(six)
                let returner = "";
                six.forEach(i => returner = returner + i + "%20");
                return returner;
            }
            return ""
        },
        lastSix(){
            if(this.$store.state.patientSeed !== ""){
                let wordarray = this.$store.state.patientSeed.split(" ");
                let six = wordarray.slice(6, 12)
                console.log(six)
                let returner = "";
                six.forEach(i => returner = returner + i + "%20");
                return returner;
            }
            return ""
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

