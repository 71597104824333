

<template>
<div class="min-h-screen bg-gray-100">
    <div class="px-4 py-5 sm:p-6">
        <main class="py-10 ounded h-auto">
            <!-- Page header -->
            <div v-if="showHeader" class="bg-white max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div class="flex items-center space-x-5">
                <div class="flex-shrink-0">
                    <div class="relative">
                    <img class="h-16 w-16 rounded-full" src="https://www.vxpass.com/assets/images/resources/vxp-logo.png" alt="" />
                    <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                    </div>
                </div>
                <div>
                    <h1 class="text-2xl text-left font-bold text-gray-900">Credentials List</h1>
                    <p class="text-sm font-medium text-gray-500">A list of your verified credentials <a href="#" class="text-gray-900"> Latest Update </a> on <time datetime="2020-08-25">{{getLatestDate()}}</time></p>
                </div>
                </div>
                <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                    Disqualify
                </button>
                <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                    Advance to offer
                </button>
                </div>
            </div>

            <div class=" h-full bg-white rounded shadow mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1">
                <div class="p-6 m-6 lg:col-start-1 lg:col-span-2">
                <!-- Description list-->
                <section aria-labelledby="applicant-information-title bg-white">
                    <div v-for="cred in creds" :key="cred.location" class="md:flex md:items-center md:justify-between md:space-x-5">
                        <div class="flex items-start space-x-5">
                        <div class="flex-shrink-0">
                            <div class="relative">
                            <img class="h-16 w-16 rounded-full" :src="cred.factory.logoUrl" alt="" />
                            <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                            </div>
                        </div>
                        <!--
                            Use vertical padding to simulate center alignment when both lines of text are one line,
                            but preserve the same layout if the text wraps without making the image jump around.
                        -->
                        <div class="pt-1.5">
                            <h1 class="text-2xl text-left font-bold text-gray-900">{{cred.factory.organization}}</h1>
                            <p class="text-sm font-medium text-gray-500">Applied for <a href="#" class="text-gray-900">Front End Developer</a> on <time datetime="2020-08-25">{{printDate(cred.issuedDate)}}</time></p>
                        </div>
                        </div>
                        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <a  target="_blank" rel="noreferrer noopen" :href="`https://run.network/explorer/?query=${cred.location}&network=test`" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            View
                        </a>
                        <button @click="useCredential(cred)" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            Issue Pass
                        </button>
                        </div>
                    </div>
                </section>
                </div>
            </div>
        </main>
    </div>
</div>

</template>








<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useStore } from "vuex"
import Run from "run-sdk";
import AccountSampleKeys from "./../../../../constants/AccountSampleKeys.js"


export default {
    components: {},
    setup () {
        let store = useStore();
        const state = reactive({
            count: 0
        })
        onMounted(async ()=>{
            let _run = new Run({network:"test", trust: '*', purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, owner: store.state.providerPrivKey })
            await store.dispatch("fetchProviderCredentials", _run);
        })
        return {
            ...toRefs(state),
        }
    },
    methods:{
        printDate(dateString){
            let d = new Date(dateString);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            console.log(`${da}-${mo}-${ye}`);
            return `${da}-${mo}-${ye}`;
        },
        getLatestDate(){
            let _d ;
            if(this.creds[0] !== undefined){
                _d= new Date(this.creds[0].issuedDate)
                this.creds.forEach(element => {
                    let _da = new Date(element.issuedDate)
                    if(_da > _d){_d = _da}
                });
            }
            if(_d)return this.printDate(_d.toString());
            else return "";
            
        },
        useCredential(cred){
            this.$store.commit("setSelectedCredential", cred);
            if(this.$route.path.includes('admin'))
                this.$router.push('/apps/admin/record/new');
            else if(this.$route.path.includes('provider'))
                this.$router.push('/apps/provider/records/new');
        }
    },
    computed:{
        creds(){
            return this.$store.state.credentials;
        },
        
    },
    props:{
        showHeader: Boolean,
    }
}
</script>

<style lang="scss" scoped>
</style>