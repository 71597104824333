<template>
    <div class="space-y-4 text-left">
        <div >
            <label for="providerName" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_NAME_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerName"  id="providerName" name="providerName" :placeholder="Language.PROVIDER_REGISTRATION_NAME_FIELD_PLACEHOLDER_TEXT" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
        <div>
            <label for="providerBusinessName" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_BUSINESS_NAME_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerBusinessName"  id="providerBusinessName" name="providerBusinessName" :placeholder="Language.PROVIDER_REGISTRATION_BUSINESS_NAME_FIELD_PLACEHOLDER_TEXT" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
         <div>
            <label for="providerBusniessEmail" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_EMAIL_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerBusinessEmail"  id="providerBusniessEmail" name="providerBusniessEmail" :placeholder="Language.PROVIDER_REGISTRATION_EMAIL_FIELD_PLACEHOLDER_TEXT" type="email" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
         <div>
            <label for="providerBusinessPhone" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_PHONE_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerBusinessPhone"  id="providerBusinessPhone" name="providerBusinessPhone" :placeholder="Language.PROVIDER_REGISTRATION_PHONE_FIELD_PLACEHOLDER_TEXT" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
         <div>
            <label for="providerMobilePhone" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_MOBILE_PHONE_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerMobilePhone"  id="providerMobilePhone" name="providerMobilePhone" :placeholder="Language.PROVIDER_REGISTRATION_MOBILE_PHONE_FIELD_PLACEHOLDER_TEXT" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
          <div>
            <label for="providerCredentialAuthority" class="block text-sm font-medium text-gray-700">
                {{Language.PROVIDER_REGISTRATION_CREDENTIAL_BY_FIELD_TITLE_TEXT}}
            </label>
            <div class="mt-1">
                <input v-model="providerCredentialAuthority"  id="providerCredentialAuthority" name="providerCredentialAuthority" :placeholder="Language.PROVIDER_REGISTRATION_CREDENTIAL_BY_FIELD_PLACEHOLDER_TEXT" type="text" autocomplete="" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
        </div>
    </div>
    <div class="flex w-full pt-6">
        <div class="flex-grow">
            <button type="button" @click="undoClick" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-indigo bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <i class='fa fa-arrow-left'></i>{{Language.BACK_TO_SIGN_IN_TEXT}} 
            </button>
        </div>
        <div class="flex-grow">
            <button class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> {{Language.SIGN_UP_TEXT}} </button>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Language from "./../../../constants/language/english.js"

export default {
    setup (props) {
        const  undoClick = () =>{
            props.showLoginClick();
        }
        const state = reactive({
            providerName: "",
            providerBusinessName: "",
            providerBusinessEmail: "",
            providerBusinessPhone: "",
            providerMobilePhone: "",
            providerCredentialAuthority: "",
        })
    
        return {
            Language,
            undoClick,
            ...toRefs(state),
        }
    },
    methods: {
       
    },
    props:["showLoginClick"]
}
</script>
