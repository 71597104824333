<template>
    <LoggedIn> 
        <template v-slot:welcomePanel>
            <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="bg-white p-6">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <div class="sm:flex sm:space-x-5">
                        <div class="flex-shrink-0">
                            <img class="mx-auto h-20 w-20 rounded-full" src="./../../../../assets/images/radial-logo.png" alt="" />
                        </div>
                        <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p class="text-sm font-medium text-gray-600">Covid-19</p>
                            <p class="text-xl font-bold text-gray-900 sm:text-2xl">Aftercare</p>
                            <p class="text-sm font-medium text-gray-600">Post Injection Record Creation</p>
                        </div>
                        </div>
                    </div>
                    <div class="invisible hidden lg:flex lg:visible lg:min-h-24 mt-5  w-full sm:mt-0">
                        <div class='m-2 '> 
                             <button type="button" class="m-1 p-1 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                                {{Language.SET_PATIENT_ADDRESS}}
                            </button>
                            
                        </div>
                        <div class='pt-2 flex-grow m-2 '>
                            <input v-model="patientAddress" class='min-w-sm w-full rounded shadow' />
                            
                        </div>
                        <div class="m-2">
                           <button @click="createPatient" type="button" class="m-1 p-1  w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ring-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                                + {{Language.CREATE_PATIENT_BUTTON_TEXT}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:actionsPanel>
             <div class="visible h-auto lg:invisible lg:h-0 rounded-lg bg-white overflow-hidden shadow">
                 <button class='w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500' @click="hideQR = !hideQR"> <i :class="hideQR ? 'far fa-eye': 'fa fa-eye-slash'"></i> {{hideQR ? "Show Reader": "Hide Reader"}}</button>
            </div>
            <div v-if="!hideQR" class=" h-50 w-50  bg-gradient-to-r from-pink-500 to-indigo-600 rounded-full  lg:invisible lg:h-0  mx-auto my-auto ">
                <div class="p-1 m-1">
                    <div  class=" mx-auto my-auto visible lg:invisible lg:h-0 rounded-full overflow-hidden shadow">
                        <div class="center h-48 w-48 mx-auto my-auto ">
                            <qr-stream @decode="onDecode" class="mb rounded-full mx-auto my-auto">
                                <div style="color: red;" class="frame"></div>
                            </qr-stream>
                        </div>
                        
                        <!-- <div class="result text-white">
                            Scanned: 
                            <div> 
                                {{data ? data["firstName"] : "" }} {{data ? data["middleInitial"] : "" }} {{data ? data["lastName"] : "" }}
                            </div>
                            <div> {{formedBirthday}}</div>
                        </div> -->
                    
                    </div>
                </div>
            </div>
            <div class="lg:invisible lg:h-0 ">
            <qr-capture @decode="onDecode" class="mb p-4 m-4 shadow"></qr-capture>
            </div>
            
            <section aria-labelledby="applicant-information-title">
                    <!-- <div class="bg-white shadow sm:rounded-lg"> -->
                        <!-- <div class="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" class="text-left text-lg leading-6 font-medium text-gray-900">
                            {{Language.NEW_RECORD_TITLE}}
                            </h2>
                            <p class="text-left mt-1 max-w-2xl text-sm text-gray-500">
                            {{Language.NEW_RECORD_SUBTITLE}}
                            </p>
                        </div> -->
                        <div class="border-t border-gray-200 ">
                            <div class="bg-white shadow sm:rounded-lg">
                                <div class="sm:p-6">
                                    <h3 class="text-3xl leading-6 font-medium text-gray-900 text-left">
                                        {{Language.PATIENT_DETAILS}}
                                    </h3>
                                    <div class="text-left mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            {{Language.NEW_RECORD_INFORMATION_DISCLAIMER}}
                                        </p>
                                    </div>
                                    <div class="mt-5 sm:flex sm:items-center rounded-xl  ring-gray-800 p-2 m-2">
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="birthday" class="block text-sm font-medium text-gray-700">{{Language.FIRST_NAME}}</label>
                                        <input v-model="firstName" type="text" name="firstName" id="firstName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Jane" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="middleName" class="block text-sm font-medium text-gray-700">{{Language.MIDDLE_INITIAL}}</label>
                                        <input v-model="middleInitial" type="text" name="middleName" id="middleName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="I" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="lastNmae" class="block text-sm font-medium text-gray-700">{{Language.LAST_NAME}}</label>
                                        <input v-model="lastName" type="text" name="lastName" id="lastName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Doe" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="birthday" class="block text-sm font-medium text-gray-700">{{Language.BIRTHDAY}}</label>
                                        <input v-model="birthday" type="date" name="birthday" id="birthday" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                        </div>
                                    </div>
                                    <hr>
                                    
                                    <div class="ring-1 ring-indigo-600 rounded-xl pb-2">
                                        <div class="pl-4 pb-4 text-left text-4xl pt-4 mt-4 font-semibold text-indigo-600 ">
                                        1st Dose 
                                    </div>
                                    <hr/>
                                        <div class="text-left mt-1 sm:flex sm:items-center rounded-xl p-2">
                                            <div class="p-1 w-full flex-grow sm:max-w-xs">
                                                <Listbox as="div" v-model="selectedManufacturer">
                                                    <ListboxLabel class="block text-sm font-medium text-gray-600">
                                                    {{Language.MANUFACTURER}}
                                                    </ListboxLabel>
                                                    <div class="mt-1 relative">
                                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                            <span class="block truncate">{{ selectedManufacturer }}</span> 
                                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </ListboxButton>

                                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                            <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer }">
                                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                                                <span :class="[selectedManufacturer ? 'font-semibold' : 'font-normal', 'block truncate']">
                                                                    {{ mfg}}
                                                                </span>

                                                                <span v-if="selectedManufacturer" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                                </li>
                                                            </ListboxOption>
                                                            </ListboxOptions>
                                                        </transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                            <div class="p-1 w-full sm:max-w-xs">
                                            <label for="lotId" class="block text-sm font-medium text-gray-600">{{Language.LOT_ID}}</label>
                                            <input v-model="lotId" type="text" name="lotId" id="lotId" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="AA65TG" />
                                            </div>
                                            <div class="p-1 w-full sm:max-w-xs">
                                            <label for="lotId" class="block text-sm font-medium text-gray-600">{{Language.DISTRIBUTED_DATE}}</label>
                                            <input v-model="dose1Date" type="date" name="dose1date" id="dose1date" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                            </div>
                                            
                                        </div>
                                        <div class="mt-1 sm:flex sm:items-center">
                                        <div class="p-1 w-full sm:min-w-md">
                                        <label for="site1Name" class="block text-left  text-sm font-medium text-gray-600">{{Language.SITE_NAME}}</label>
                                        <input v-model="site1Name" type="text" name="site1Name" id="site1Name" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Quiet Waters Park" />
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div class="ring-1 ring-pink-600 rounded-xl pb-2">
                                        <div class=" pl-4 pb-4 text-left text-4xl pt-4 mt-4 font-semibold text-pink-600">
                                        2nd Dose 
                                    </div>
                                    <hr/>
                                      <div class="text-left mt-1 sm:flex sm:items-center rounded-xl p-2">
                                        <div class="p-1 w-full flex-grow sm:max-w-xs ">
                                            
                                            <Listbox as="div" v-model="selectedManufacturer2">
                                                <ListboxLabel class="block text-sm font-medium text-gray-600">
                                                {{Language.MANUFACTURER}}
                                                </ListboxLabel>
                                                <div class="mt-1 relative">
                                                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                        <span class="block truncate">{{ selectedManufacturer2 }}</span>
                                                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </ListboxButton>

                                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                        <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer2 }">
                                                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                                            <span :class="[selectedManufacturer2 ? 'font-semibold' : 'font-normal', 'block truncate']">
                                                                {{ mfg}}
                                                            </span>

                                                            <span v-if="selectedManufacturer2" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                            </li>
                                                        </ListboxOption>
                                                        </ListboxOptions>
                                                    </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="lotId2" class="block text-sm font-medium text-gray-600">{{Language.LOT_ID}}</label>
                                        <input v-model="lotId2" type="text" name="lotId2" id="lotId2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="AA65TG" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="doseDate2" class="block text-sm font-medium text-gray-600">{{Language.DISTRIBUTED_DATE}}</label>
                                        <input v-model="doseDate2" type="date" name="doseDate2" id="doseDate2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                        </div>
                                        
                                    </div>
                                    <div class="mt-1 sm:flex sm:items-center text-left">
                                        <div class="p-1 w-full sm:min-w-md">
                                        <label for="siteName2" class="block text-sm font-medium text-gray-600">{{Language.SITE_NAME}}</label>
                                        <input v-model="siteName2" type="text" name="siteName2" id="siteName2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Quiet Waters Park" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a @click="showConfirm = true" class="block bg-gray-50 text-sm font-medium text-indigo-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">{{Language.SAVE_VACCINATION_RECORDS}}</a>
                        </div>
                    <!-- </div> -->
                </section>
                <div v-if="showConfirm">
                    <two-button-wide name="confirmModal" @modalConfirmed="confirmFromModal" @modalCanceled="showConfirm = false" title="Saving " message="Please Confirm the details before saving the record." confirmText="Confirm">
                        <template v-slot:content  >
                            <div class=" shadow-xl">
                            <div class="flex flex-col bg-white">
                                <div class="text-md p-1 m-1 ">
                                    {{firstName}}
                                    {{middleInitial}}
                                    {{lastName}}
                                    {{birthday}}
                                    
                                </div>
                                <div class="text-xs text-gray-600 underline"> ID: </div>
                                <div>  {{patientAddress}}</div>
                                
                                <div class="flex flex-1">
                                <div class="text-md p-1 m-1 flex-grow shadow bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-800 text-white">
                                    <div class="text-xs  underline">Dose 1 </div>
                                    <div class="font-extrabold" > {{selectedManufacturer}} </div>
                                    <div> {{lotId}}</div> 
                                    <div>{{dose1Date}}</div>
                                   <div>{{site1Name}} </div>
                                </div>
                                <div class="text-md p-1 m-1 flex-grow shadow bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700 text-gray-50">
                                <div class="text-xs underline">Dose 2 </div>
                                <div class="font-extrabold "> {{selectedManufacturer}} </div>
                                <div> {{lotId2}}</div>
                                <div> {{doseDate2}}</div>
                                <div>{{siteName2}}</div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </template>
                    </two-button-wide>
                </div>
        </template>
         <template v-slot:rightColumn>
             <section aria-labelledby="announcements-title">
              <div class="rounded-lg bg-white overflow-hidden shadow">
                  <div class="p-6">
                  <h2 class="text-base font-medium text-gray-900" id="announcements-title">Status</h2>
                  <div class="flow-root mt-6">
                    <ul class="-my-5 divide-y divide-gray-200">
                
                    <li v-for="(item, itemIdx) in timeline" :key="item.id">
                    <div class="relative pb-8">
                        <span v-if="(itemIdx !== timeline.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                        <div class="relative flex space-x-3">
                        <div>
                            <span :class="[item.type.bgColorClass, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                            <component :is="item.type.icon" class="w-5 h-5 text-white" aria-hidden="true" />
                            </span>
                        </div>
                        <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                            <p class="text-sm text-gray-500">
                                <a href="#" class="font-medium text-gray-900">{{ item.target }}</a> {{ item.lotId }}
                            </p>
                            <p class="text-sm text-gray-500">{{item.site}}</p>
                            </div>
                            <div class="text-right text-sm whitespace-nowrap text-gray-500">
                            <time :datetime="item.datetime">{{ item.date }}</time>
                            </div>
                        </div>
                        </div>
                    </div>
                    </li>
                </ul>
              </div>
               <button type="button" @click="showConfirm = true" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        {{Language.SAVE_VACCINATION_RECORDS}}
                    </button>
                  </div>
                  
              </div>
              
             </section>
            <section class='invisible hidden bg-white lg:inline-flex'> 
                <div class="rounded-lg bg-white overflow-hidden shadow px-1 mx-1">
                     <div class=" h-50 w-50 bg-gradient-to-r from-pink-500 to-indigo-600 mx-auto  ">
                        <div class="p-1 m-1">
                            <div v-if="!hideQR" class=" visible overflow-hidden shadow">
                                <div class="center mx-auto my-auto ">
                                    <qr-stream @decode="onDecode" class="mb rounded-full mx-auto my-auto">
                                        <div style="color: red;" class="frame frame-lg"></div>
                                    </qr-stream>
                                </div>
                                
                                <!-- <div class="result text-white">
                                    Scanned: 
                                    <div> 
                                        {{data ? data["firstName"] : "" }} {{data ? data["middleInitial"] : "" }} {{data ? data["lastName"] : "" }}
                                    </div>
                                    <div> {{formedBirthday}}</div>
                                </div> -->
                            
                            </div>
                        </div>
                    </div>
                     <qr-capture @decode="onDecode" class="mb p-4 m-4 shadow"></qr-capture>
                </div>
            </section>
        </template>
    </LoggedIn>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import LoggedIn from './../layouts/LoggedIn.vue'
import Run from "run-sdk"
import Utilities from '../../../../helpers/Utilities'
import AccountSampleKeys from "./../../../../constants/AccountSampleKeys.js"
import VXConstants from "./../../../../constants/VXConstants.js"
import VXVaccineConstants from "./../../../../constants/VXVaccineConstants.js"
import VXTransactionHelper from "./../../../../helpers/VXTransactionHelper.js";
import TwoButtonWide from "./../../../../components/tailwind/modals/TwoButtonWide.vue"
import Language from "./../../../../constants/language/english.js";
import {useStore} from "vuex"
import {
  Listbox, 
  ListboxButton, 
  ListboxLabel, 
  ListboxOption, 
  ListboxOptions
} from '@headlessui/vue'
import {
  CheckIcon,
  SelectorIcon,
  UserIcon,
  ThumbUpIcon,
  QuestionMarkCircleIcon
} from '@heroicons/vue/solid'
const manufacturers  = ["Pfizer", "Moderna", "Jansen"];
const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  unknown: { icon: QuestionMarkCircleIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
}
import { QrCapture } from "vue3-qr-reader";
export default {
    components:{LoggedIn,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
        TwoButtonWide,
        QuestionMarkCircleIcon,
        QrCapture
        },
    setup () {
        let store = useStore();
        console.log(store.state.selectedCredential)
       const selectedManufacturer = ref(manufacturers[0])
       const selectedManufacturer2 = ref(manufacturers[0])
      
        const onDecode = async(data) => {
            console.log(data.toString());
            if(data.toString().length > 0){
                state.data = JSON.parse(data);
                if(state.data["firstName"]){state.firstName = state.data["firstName"]}
                if(state.data["middleInitial"]){state.middleInitial = state.data["middleInitial"]}
                if(state.data["lastName"]){state.lastName = state.data["lastName"]}
                if(state.data["birthday"]){state.birthday = state.data["birthday"]}
                if(state.data["address"]){state.address = state.data["address"]}
            }
        }
       
       let state = reactive({
           firstName: "",
           middleInitial: "",
           lastName: "",
           birthday: null,
           lotId: "",
           dose1Date: new Date(),
           site1Name:"",
           lotId2: "",
           doseDate2: new Date(),
           siteName2:"",
           patientAddress: store.state.patientAddress,
           showConfirm: false,
           data: null,
           hideQR: false
       })
    
        return {
            manufacturers,
            store,
            selectedManufacturer,
            selectedManufacturer2,
            Language,
            onDecode,
            ...toRefs(state)
        }
    },
    methods:{
        async createRecords(){
            try{
                let _run = new Run({network:"test", trust: "*", purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, owner: this.$store.state.providerPrivKey});
                console.log( this.patientAddress);
                let _ndc = VXVaccineConstants.VACCINE_NDC_CODES_1[this.selectedManufacturer];
                let options1 = {manufacturer: this.selectedManufacturer, ndc: _ndc, lot: this.lotId, expiry: Utilities.dateString(+365), distributed: this.dose1Date, site:this.site1Name, firstName: this.firstName.trim(), mi: this.middleInitial.trim(), lastName: this.lastName.trim()}
                let selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                let _class = await _run.load(VXConstants.VACCINATION_RECORD_CLASS_LOCATION);
                await _class.sync()
                let _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, this.sendTo, options1);
                let options2 = {manufacturer: this.selectedManufacturer2, ndc: _ndc, lot: this.lotId2, expiry: Utilities.dateString(+365), distributed: this.doseDate2, site:this.siteName2, firstName: this.firstName.trim(), mi: this.middleInitial.trim(), lastName: this.lastName.trim()}
                _tx =  VXTransactionHelper.addRecordToTransaction(_run, _tx, selectedCredential, _class, this.sendTo, options2);
                let result =  await _tx.publish();
                if(result){console.log(result);}
        
                // let toVerify = await _run.load(result + "_o1");
                // let verified = Utilities.verifyRecordSig(toVerify);
                }catch(err){console.log(err); alert(err)}
                this.showConfirm = false;
                //TODO: Add Modal For Success Here. 
                alert(Language.SAVE_RECORD_SUCCESS_MESSAGE);
        },
         createPatient(){
            let _run = new Run({network:"test", trust: "*"});
            this.patientAddress = _run.owner.address;
            console.log(this.patientAddress);
            this.$store.commit("setPatientAddress", _run.owner.address);
            this.$store.commit("setPatientPrivKey", _run.owner.privkey);
            //this.$store.commit("setPatientSeed", random.seed);
            //console.log(_run.owner.privkey);
        },
        setAddress(){
            this.$store.commit('setPatientAddress', this.patientAddress);
        },
        async confirmFromModal(){
            if(this.addresss === ""){alert("Pateint Blockchain Account Not Created")}
            console.log("Confirmed")
            await this.createRecords();
        },
    },
    computed:{
        timeline(){
            let nameOrBirthdayMissing = (this.firstName === "" || this.lastName === "" || this.birthday < new Date());
            let firstComplete = (this.selectedManufacturer !== "" && this.lotId !== "" )
            let secondComplete = (this.selectedManufacturer !== "" && this.lotId2 !== "" )
            console.log("Stored:", this.dose1Date.toString());
            let d1 = new Date(this.dose1Date.toString() + "T00:00:00");
            let d1String = new Date(d1).toString();
            let d2 = new Date(this.doseDate2.toString() + "T00:00:00");
            let d2String = new Date(d2).toString();
            let localDate1; 
            if(d1String !== "Invalid Date" ){localDate1 =  d1.toDateString('en-us')} else{firstComplete = false}
            let localDate2;
            if(d2String !== "Invalid Date" ){localDate2 = d2.toDateString('en-us')} else{secondComplete = false}
        
            let first = {id:1, type: nameOrBirthdayMissing ? eventTypes.unknown : eventTypes.advanced, target: (this.firstName + " " + this.middleInitial + " " + this.lastName), lotId: this.birthday, date: "", datetime:"" }
            let second = {id:2, type: firstComplete ? eventTypes.advanced : eventTypes.unknown , target: this.selectedManufacturer, lotId: "lotID: " + this.lotId, date: localDate1, datetime:"", site: this.site1Name}
            let third = {id:3, type: secondComplete ? eventTypes.advanced : eventTypes.unknown , target: this.selectedManufacturer2, lotId: "lotID: " + this.lotId2, date: localDate2, datetime:"", site: this.siteName2}
             const _timeline = [
                first,
                second,
                third,
                ]
                return _timeline
        },
        formedBirthday(){
            if(!this.data)return "";
            if (!this.data.birthday) return "";
            let dateArr = this.data.birthday.split("-");
            return `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
        },
        sendTo(){
            if(this.data != null && ['address'] != null){
                return this.data["address"];
            }
            if(this.patientAddress !== "" ){
                return this.patientAddress;
            }
            return this.$store.state.patientAddress;
        }
    },
}
</script>

<style scoped>
.stream {
  max-height: 300px;
  max-width: 300px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
.frame-lg{
  height: 200px;
  width: 200px;
}

label{
    font-weight:bold
}
</style>