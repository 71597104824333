<template>
    <settings-left-icon-menu 
    navClassOverride="bg-gradient-to-r from-pink-400 via-pink-400 to-pink-500" 
    sidebarClassOverride="bg-gradient-to-t from-purple-500 via-pink-600 to-pink-500" 
    :mainNavItems="navigation" >
            <template v-slot:form >
                <slot name="content"></slot>
            </template>
            <template v-slot:userSidebar > 
              <div> </div>
            </template>
            <template v-slot:topMenuUser > 
              <div> </div>
            </template>
    </settings-left-icon-menu>
</template>

<script>
import { reactive, toRefs } from 'vue'
//import Header from './../../../components/apps/patient/menus/Header.vue'
import SettingsLeftIconMenu from './../../../../components/tailwind/pages/SettingsLeftIconMenu.vue';
import {
  //BellIcon,
  HomeIcon,
  KeyIcon,
  ColorSwatchIcon,
  UserIcon,
  LogoutIcon,
  PhotographIcon,
} from '@heroicons/vue/outline'

const navigation = [
  { name: 'Home', href: '/apps/patient/home', icon: HomeIcon },
  { name: 'Records', href: '/apps/patient/covid', icon: ColorSwatchIcon },
  { name: 'Profile', href: '/apps/patient/profile', icon: UserIcon },
  { name: 'Keys', href: '/apps/patient/keys', icon: KeyIcon },
  { name: 'Photo', href: '/apps/patient/photo', icon: PhotographIcon },
  { name: 'Logout', href: '/apps/patient/login', icon: LogoutIcon },
]

export default {
  components: {SettingsLeftIconMenu},
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
            navigation
        }
    }
}
</script>

<style lang="scss" scoped>

</style>