<template>
    <div class="grid grid-cols-2 items-center items-justify-center"> 
        <div class='col-span-1 pr-1'>
            <i :class="statusClasses"> </i>
        </div>
        <div class="col-span-1 pl-1 text-warm-gray-600">
            <p class='text-2xl text-warm-gray-700'> 
            {{titleText}}
            </p>
            <p>{{details}}</p>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    props:['statusClasses', 'titleText', "details"]
}
</script>

<style scoped>

</style>