<template>
<div class="mt-3 h-14 w-32 mx-auto"> 
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.28 66.23"><defs></defs><path class="cls-1" d="M423.39,404.44l7.7-52.31h9.25l-9.9,64.13h-15l-9.9-64.13h10.17Z" transform="translate(-404.94 -351.08)"/><path class="cls-1" d="M478.64,352.13,468.1,383.28l11.27,33H468.74l-8.24-25.38-8.34,25.38h-9.44l11.27-33-10.53-31.15H453.9l7.6,23.64,7.79-23.64Z" transform="translate(-404.94 -351.08)"/><path class="cls-1" d="M497.69,352.13c9.9,0,13.75,6.23,13.75,15.58v7c0,10.17-4.68,15.21-14.84,15.21h-8v26.38h-4.21V352.13ZM496.6,386c7.42,0,10.62-3.3,10.62-11.09v-7.51c0-7-2.38-11.45-9.53-11.45h-9.07v30Z" transform="translate(-404.94 -351.08)"/><path class="cls-1" d="M522.89,402.06l-3.21,14.2h-3.84l14-64.22H536l14.3,64.22h-4.22l-3.21-14.2Zm.64-3.66h18.69l-9.43-41.51Z" transform="translate(-404.94 -351.08)"/><path class="cls-1" d="M583.73,367.61V369h-4v-1.65c0-6.78-2.56-11.91-9.52-11.91s-9.53,5-9.53,11.82c0,15.57,23.18,15.85,23.18,33.53,0,9.25-4,16-13.84,16s-13.83-6.78-13.83-16v-3.3h4v3.57c0,6.88,2.66,11.82,9.71,11.82s9.71-4.94,9.71-11.82c0-15.39-23.18-15.66-23.18-33.53,0-9.62,4.22-15.85,13.65-15.94C579.79,351.58,583.73,358.36,583.73,367.61Z" transform="translate(-404.94 -351.08)"/><path class="cls-1" d="M617.63,367.61V369h-4v-1.65c0-6.78-2.57-11.91-9.53-11.91s-9.53,5-9.53,11.82c0,15.57,23.18,15.85,23.18,33.53,0,9.25-4,16-13.84,16s-13.83-6.78-13.83-16v-3.3h4v3.57c0,6.88,2.66,11.82,9.71,11.82s9.71-4.94,9.71-11.82c0-15.39-23.18-15.66-23.18-33.53,0-9.62,4.22-15.85,13.65-15.94C613.69,351.58,617.63,358.36,617.63,367.61Z" transform="translate(-404.94 -351.08)"/></svg>
</div>
</template>

<script>
export default {
    setup () {
        return {}
    }
}
</script>

<style scoped>
.cls-1{fill:#fff;stroke:#fff;stroke-miterlimit:10;}
</style>