let bsv = require("bsv");
let Mnemonic = require("bsv/mnemonic");
let defaultPath = "m/44'/0'/0'";
module.exports = {
    randomSeed(){
        return Mnemonic.fromRandom().toString()
    },
    privateKeyFromSeed(_seed, _path, _network){
        let response = null; 
        console.log({_path});
        if(!_path  || _path === ""){_path = defaultPath}
        if(!_network || _network === null){_network = "testnet"}
        try{
            const mnemonic = Mnemonic.fromString(_seed.trim());
            const hdPrivKey = bsv.HDPrivateKey.fromSeed(mnemonic.toSeed(), _network)
            const childKey = hdPrivKey.deriveChild(_path) 
            response = childKey.privateKey.toString()
            console.log(mnemonic.toString(), hdPrivKey.toString(), _network, childKey.toString(), response)
        }catch(err){response = err}
        return response; 
    },
    addressFromSeed(_seed, _path, _network){
        if(!_path || _path === ""){_path = defaultPath}
        if(!_network || _network === '' ){_network = "testnet"}
        console.log({_network});
        let response = null; 
        try{
            const mnemonic = Mnemonic.fromString(_seed.trim());
            const hdPrivKey = bsv.HDPrivateKey.fromSeed(mnemonic.toSeed(), _network)
            const childKey = hdPrivKey.deriveChild(_path) 
            const privateKey = childKey.privateKey;
            console.log(privateKey.toString());
            let address = bsv.Address.fromPrivateKey(privateKey)

            response = address.toString();
            console.log(mnemonic.toString(), hdPrivKey.toString(), _network, childKey.toString(), address.toString(), response)
        }catch(err){console.log(err);response = err}
        return response; 
       
    }
}