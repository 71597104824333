<template>
    <div>
        <logged-in >
            <template v-slot:content>
                    <h1 class="text-3xl font-extrabold text-blue-gray-900 md:mt-8 py-2">Account</h1>
                    <div class="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-8 sm:gap-x-8">
                        <div class="sm:col-span-8">
                        <h2 class="text-xl font-medium text-blue-gray-900">Profile</h2>
                        <p class="mt-1 text-sm text-blue-gray-500"> If you choose to save this information it will <span class="text-red-600"> NOT </span> be stored on the Blockchain.</p>
                        </div>
                         <div class="bg-gradient-to-r from-pink-500 to-indigo-500 rounded-xl p-4 m-2 shadow grid sm:col-span-8 sm:grid-cols-8 ">
                            <div class="sm:col-span-3 p-1">
                            <label for="first_name" class="block text-sm font-medium text-white">
                                {{Language.FIRST_NAME}}
                            </label>
                            <input v-model="firstName" type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                            </div>
                            <div class="sm:col-span-2 p-1">
                            <label for="middleInitial" class="block text-sm font-medium text-white">
                                {{Language.MIDDLE_INITIAL}}
                            </label>
                            <input v-model="middleInitial" type="text" name="middleInitial" id="middleInitial" autocomplete="family-name" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                            </div>

                            <div class="sm:col-span-3 p-1">
                            <label for="last_name" class="block text-sm font-medium text-white">
                                {{Language.LAST_NAME}}
                            </label>
                            <input v-model="lastName" type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                            </div>
                         
                            <div class="sm:col-span-8">
                            <label for="username" class="block text-sm font-medium text-white">
                                {{Language.BIRTHDAY}}
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                                <input v-model="birthday" type="date" name="birthday" id="birthday" class="flex-1 block w-full min-w-0 border-blue-gray-300 rounded-none rounded-r-md text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                            </div>
                        </div>
                        </div>
                        <div class="bg-white rounded-xl shadow  p-4 m-2 pt-6 sm:pt-5 sm:col-span-8">
                            <div role="group" aria-labelledby="label-notifications">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                                <div>
                                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-notifications">
                                    Gender
                                </div>
                                </div>
                                <div class="sm:col-span-3">
                                    <div class="max-w-lg">
                                        <p class="text-sm text-gray-500">This information is never tied to your account. It is optional for reporting total demographic count.</p>
                                        <div class="flex w-full justify-items-center mt-4">
                                            <div class="flex flex-grow  items-center ">
                                                <input v-model="gender" value="Female" id="patient_gender_female" name="patientGender" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_gender_female" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Female
                                                </label>
                                            </div>
                                            <div class="flex flex-grow items-center ">
                                                <input v-model="gender" value="Male" id="patient_gender_male" name="patientGender" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_gender_male" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Male
                                                </label>
                                            </div>
                                            <div class="flex flex-grow items-center ">
                                                <input v-model="gender" value="None" id="patient_gender_none" name="patientGender" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_gender_none" class="ml-3 block text-sm font-medium text-gray-700">
                                                    None
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="bg-white rounded-xl shadow  p-4 m-2 pt-6 sm:pt-5 sm:col-span-8">
                            <div role="group" aria-labelledby="label-notifications">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                                <div>
                                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-notifications">
                                    Race
                                </div>
                                </div>
                                <div class=" text-xs flex col-span-3 sm:col-span-3 sm:text-md">
                                    <div class="max-w-xl">
                                        <p class="text-sm text-gray-500">This information is never tied to your account. It is optional for reporting total demographic count.</p>
                                        <div class="grid md:grid-cols-6 mt-4 ">
                                            <div class="flex items-center  pl-1">
                                                <input v-model="race" value="African American" id="patient_african_american" name="patientRace" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_african_american" class="ml-3 block text-sm font-medium text-gray-700">
                                                    African American
                                                </label>
                                            </div>
                                            <div class="flex items-center  pl-1">
                                                <input v-model="race" value="Asian" id="patient_asian" name="patientRace" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_asian" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Asian
                                                </label>
                                            </div>
                                             <div class="flex items-center pl-1">
                                                <input v-model="race" id="patient_white" value="White" name="patientRace" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_white" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Caucasian
                                                </label>
                                            </div>
                                            <div class="flex items-center  pl-1">
                                                <input v-model="race" id="patient_native_american" value="Native American" name="patientRace" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_native_american" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Native American
                                                </label>
                                            </div>
                                            <div class="flex items-center  pl-1">
                                                <input v-model="race" id="patient_non_white_latin" value="Non White Latin" name="patientRace" type="radio" class="focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" />
                                                <label for="patient_non_white_latin" class="ml-3 block text-sm font-medium text-gray-700">
                                                    Latin
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <!-- <div class="sm:col-span-8">
                        <label for="description" class="block text-sm font-medium text-blue-gray-900">
                            Description
                        </label>
                        <div class="mt-1">
                            <textarea id="description" name="description" rows="4" class="block w-full border border-blue-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                        <p class="mt-3 text-sm text-blue-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                        </div>

                        <div class="sm:col-span-8">
                        <label for="url" class="block text-sm font-medium text-blue-gray-900">
                            URL
                        </label>
                        <input type="text" name="url" id="url" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div> -->
                    </div>

                    <!-- <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-8 sm:gap-x-6">
                        <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-blue-gray-900">Personal Information</h2>
                        <p class="mt-1 text-sm text-blue-gray-500">This information will be displayed publicly so be careful what you share.</p>
                        </div>

                        <div class="sm:col-span-4">
                        <label for="email_address" class="block text-sm font-medium text-blue-gray-900">
                            Email address
                        </label>
                        <input type="text" name="email_address" id="email_address" autocomplete="email" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-4">
                        <label for="phone_number" class="block text-sm font-medium text-blue-gray-900">
                            Phone number
                        </label>
                        <input type="text" name="phone_number" id="phone_number" autocomplete="tel" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-4">
                        <label for="country" class="block text-sm font-medium text-blue-gray-900">
                            Country
                        </label>
                        <input type="text" name="country" id="country" autocomplete="country" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <div class="sm:col-span-4">
                        <label for="language" class="block text-sm font-medium text-blue-gray-900">
                            Language
                        </label>
                        <input type="text" name="language" id="language" class="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>

                        <p class="text-sm text-blue-gray-500 sm:col-span-6">This account was created on <time datetime="2017-01-05T20:35:40">January 5, 2017, 8:35:40 PM</time>.</p>
                    </div> -->

                    <div class="pt-8 flex justify-end">
                        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Cancel
                        </button>
                        <button @click="save" type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Save</button>
                    </div>
                    </div>
                </template>
        </logged-in>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore} from "vuex"
//import SettingsLeftIconMenu from './../../../components/tailwind/pages/SettingsLeftIconMenu.vue';
import LoggedIn from './layouts/LoggedIn.vue';
import Language from "./../../../constants/language/english.js";
//import Navigation from "./../../../constants/ApplicationConstants.js";



export default {
  components: { LoggedIn },
  
    setup () {
        let store = useStore();
        let firstName = store.state.patientFirstName;
        let middleInitial = store.state.patientMiddleInitial;
        let lastName = store.state.patientLastName;
        let birthday = store.state.patientBirthday;
        let gender = store.state.patientGender;
        let race = store.state.patientRace;
        
        
        
        const state = reactive({
          firstName: firstName,
          middleInitial: middleInitial,
          lastName:lastName,
          birthday: birthday,
          gender: gender,
          race: race
        })

        console.log(state);
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods:{
        async save(){
            let details = {firstName: this.firstName, middleInitial: this.middleInitial, lastName: this.lastName, birthday: this.birthday, gender: this.gender, race: this.race }
            this.$store.dispatch("updatePatientDetails", details);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>