<template>
<div>
 <logged-in>
        <template v-slot:content>
            <div class="h-full mx-auto rounded md:mt-8 shadow p-2 max-w-md">
                <div class="bg-white">
                    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl text-center">
                        <div class='flex-col pt-6 pb-10'>
                            <div class="text-4xl font-semibold text-gray-500">
                                Your Card
                            </div>
                            <div class="text-sm font-normal pb-4 text-gray-500">
                                    Select a photo of your card to be presented with your QRCode. 
                            </div>
                            <div>
                                <input ref="photoSelector" type="file" id="input" accept=".jpg, .jpeg, .png" single v-on:change="readImage">
                                <img ref="picture"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
 </logged-in>
    
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import LoggedIn from "./layouts/LoggedIn.vue"
export default {
    components:{LoggedIn},
    setup () {
        const state = reactive({
            photo: null,
        })
    
        return {
            ...toRefs(state),
        }
    },
    mounted(){
        let file =  localStorage.card_file2;
        console.log(file);
        this.$refs.picture.src = file;
    },
    methods:{
        async readImage() {
            var file = this.$refs.photoSelector.files[0];
            // Check if the file is an image.
            if (file.type && !file.type.startsWith('image/')) {
                console.log('File is not an image.', file.type, file);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('progress', (event) => {
                if (event.loaded && event.total) {
                    const percent = (event.loaded / event.total) * 100;
                    console.log(`Progress: ${Math.round(percent)}`);
                }
            });
            reader.addEventListener('load', (event) => {
                this.$refs.picture.src = event.target.result;
                this.photo = event.target.result;
                localStorage.card_file2 = event.target.result;
            });
            reader.readAsDataURL(file);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>