<template>
    <div class="md:flex ">
        <div class='flex-grow' >
            <label for="stateID" class="block text-sm font-medium text-gray-700">
            State License ID 
            </label>
            <div class="mt-1 flex rounded-md shadow-sm p-2 m-2 bg-gray-200">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                ID
            </span>
            <input v-model="stateID" type="text" name="stateID" id="stateID" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300" placeholder="3564736576788" />
            </div>
        </div>
        <div class='flex-grow'>
            <label for="address" class="block text-sm font-medium text-gray-700">
            Verified VXID
            </label>
            <div class="mt-1 flex rounded-md shadow-sm p-2 m-2 bg-gray-200">
                 
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                Address
            </span>
           
            <input v-model="address" type="text" name="address" id="address" class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300" placeholder="3564736576788" />

            </div>
           
        </div>
         <div  class='flex-initial justify-right pt-6'>
                 <button @click="saveCredential" type="button" class="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <PlusIcon class="h-6 w-6" aria-hidden="true" />
                </button> 
            </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {mapActions } from "vuex";
import {PlusIcon } from "@heroicons/vue/outline"
import Run from "run-sdk"
import BSV from "bsv"
import MedicalIDValidator from "./../../../../validators/USStateMedicalIdValidators.js"
import Utilities from '../../../../helpers/Utilities.js';
import SampleKeys from "./../../../../constants/AccountSampleKeys.js"
export default {
    setup () {
        let run = new Run({network:"test", purse: SampleKeys.PAYMENT_PRIVATE_KEY,  trust: "*"})
        const state = reactive({
            count: 0,
            stateID: "ME4565", 
            address: run.owner.address
        })
    
        return {
            ...toRefs(state),
        }
    },
    methods: {
        async saveCredential() {
            this.address = this.address.trim();
            if(this.stateID === "" || this.address === "" ){
                alert("A state ID and a Blockchain address are required."); 
                return
            }else{
                if (this.isAddress(this.address) && this.isCredential(this.stateID) && this.$store.state.selectedFactory !== null){
                    let run = new Run({network:"test", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: this.$store.state.adminPrivKey, trust: "*"})
                    await run.activate();
                    let f = await run.load(this.$store.state.selectedFactory.location);
                    await f.sync();
                    console.log({f})
                    f.createCredentials(this.address, this.stateID, Utilities.dateString(0))
                    await f.sync()
                    this.$store.commit("setSelectedFactory", f);
                }else{
                    alert("One of the inputs was invalid")
                    return false; 
                }
            }
            return; 
        }, 
        isAddress(address){
            try{BSV.Address.fromString(address); return true;}catch(err){alert(err);  return false;}
        },
        isCredential(stateID){
            let isValid = MedicalIDValidator.floridaMedicine(stateID)
            if(!isValid){alert("A Medical License starts with ME and is at least 4 digits long")}
            return isValid
        }
    },
    computed:{
        ...mapActions({
            addCred: 'addCredentialToFactory' // map `this.add()` to `this.$store.dispatch('increment')`
        })
    },
    components:{
        PlusIcon
    }
}
</script>

<style lang="scss" scoped>

</style>