<template>
    <nav class='flex-grow mx-auto'>
        <ol class="flex items-center ">
            <li v-for="(step, stepIdx) in steps" :key="step.name" class='flex-grow items-center justify-center' :class="[stepIdx !== steps.length - 1 ? 'pr-10' : '', 'relative']">
                 <template v-if="step.status === 'complete'">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="h-0.5 w-full bg-indigo-600" />
                    </div>
                    <a href="#" class="relative w-12 h-12 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900 text-white">
                        <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
                      
                        <span class="sr-only">{{ stepIdx + 1 }}</span>
                    </a>
                 </template>
                 <template v-if="step.status === 'current'">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="h-0.5 w-full bg-indigo-600" />
                    </div>
                    <a href="#" class="relative w-12 h-12 flex items-center justify-center bg-teal-400 ring-white ring-2 rounded-full hover:bg-indigo-900 text-white">
                          {{ stepIdx + 1 }}
                        <span class="sr-only">{{ step.name }}</span>
                    </a>
                 </template>
                 <template v-if="step.status === 'upcoming'">
                    <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="h-0.5 w-full bg-indigo-600" />
                    </div> -->
                    <a href="#" class="relative w-12 h-12 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900 text-white">
                          {{ stepIdx + 1 }}
                        <span class="sr-only">{{ step.name }}</span>
                    </a>
                 </template>
                  <template v-if="step.stepType === 'final'">
                    <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="h-0.5 w-full bg-indigo-600" />
                    </div> -->
                    <a href="#" :class="finalClasses">
                         <img class='h-24 w-24' src="./../../../../assets/images/VXPASS-Provider-Circle-Logo.png">
                    </a>
                 </template>
            </li>
        </ol>
    </nav> 
</template>

<script>
import { reactive, toRefs } from 'vue'
import {CheckIcon} from "@heroicons/vue/outline"

export default {
    components:{CheckIcon},
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
        finalClasses(){
            return "animate-pulse";
        }
    },
    props:["steps"]
}
</script>

<style lang="scss" scoped>

</style>