<template>
<lite-menu> </lite-menu>
    <div>
        <div class="sm:text-center">
            <h2 class="text-3xl font-extrabold text-indigo-500 tracking-tight sm:text-4xl">
                Your Secret Code
            </h2>
            
            <p  v-if="!showSeedInput" class="mt-6 mx-auto max-w-2xl text-lg font-semibold text-gray-600">
                {{seed}}
            </p>
            <div  v-if="!showSeedInput"> 
                <button @click="clearSeed" class='p-2 m-2 bg-red-600 text-white rounded-xl shadow-xl hover:bg-red-800 '> Use A Different Code </button>
            </div>
            <div class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                <div v-if="showSeedInput" class="min-w-0 flex-1">
                    <label for="secretCode" class="sr-only">Secret Code</label>
                    <input v-model="seed" id="secretCode" type="text" class="block ring-2 ring-indigo-600 w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" placeholder="green story flowers..." />
                </div>
            </div>
            <div class="flex items-center justify-center pt-2">
                 <div v-if="showSeedInput" class="mt-4 sm:mt-0 sm:ml-3">
                    <button @click="setSeed" type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10">Use Secret</button>
                </div>
                <div v-if="showSeedInput" class="mt-4 sm:mt-0 sm:ml-3">
                    <button @click="undo" type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-white text-base font-medium text-red-500 shadow hover:bg-gray-100 focus:outline-none ring-2 ring-red-500 focus:ring-offset-2 focus:ring-offset-red-600 sm:px-10">Cancel</button>
                </div>
            </div>
        </div>
        <div> 
            <div class="bg-white py-1 sm:py-4">
                <div class="relative sm:py-16">
                    <div aria-hidden="true" class="hidden sm:block">
                        <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                        <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
                        <defs>
                            <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                        </svg>
                    </div>
                    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div class="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                            <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                                <path class="text-indigo-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                                <path class="text-indigo-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                                </svg>
                            </div>
                            <div class="relative">
                                
                                <div class="relative grid grid-cols-1 sm:grid-cols-2 space-x-2">
                                    <div class="col-span-1 bg-white rounded-xl grid grid-cols-1">
                                        <div class="sm:text-center p-4 col-span-1">
                                            <StatusMini doseType="first" :status="firstDoseStatus" /> 
                                            <FormMini :doseIndex="1"/>
                                        </div>
                                        <div class='sm:text-center p-4 col-span-1'>
                                            <StatusMini doseType="second" :status="secondDoseStatus" /> 
                                            <FormMini :doseIndex="2" />
                                        </div>
                                    </div>
                                     <div class="bg-white rounded-xl col-span-1 mt-2">
                                        <div class="sm:text-center  p-4">
                                            <PatientDetailsAndForm v-on:patient-saved="patientSaved" :showAddress="false" :includeIds="false" :edit="editPatientDetails" :savePatientInfo="savePatientInfo" :close="hidePatientEdit" :showEditFunction="showPatientEdit"/>
                                        </div>
                                    </div>
                                </div>
                                <div class='flex p-4 m-4'>
                                    <div class='flex-grow'></div>
                                    <div class='flex'>
                                        <VerticalTimeline :steps="steps" :canSend="showSendButton" :sendFunction="sendButtonClick"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <two-button-wide-modal :open="showModal" v-on:modal-confirmed="confirmClicked" v-on:modal-canceled="cancelClicked" title="" message="The details of this confirmation can not be changed. Please double check that all the information is complete." confirmText="Send"> 
            <template v-slot:content >
                <div class='pt-6 flex space-x-3 items-center'>
                    <div class='flex-grow shadow-xl' >
                        <dose-details-mini statusClasses="fa fa-syringe text-3xl text-indigo-600" :titleText="dose1Manufacturer" :details="doseDetails(1)"></dose-details-mini>
                    </div>
                    <div class='flex-grow shadow-xl' >
                        <dose-details-mini statusClasses="fa fa-syringe text-3xl text-indigo-600" :titleText="dose2Manufacturer" :details="doseDetails(2)"></dose-details-mini>
                    </div>
                </div>
                <div class='grid grid-cols-2'>
                    
                    <div class="col-span-1 pt-6 montserrat items-left text-left">
                            <label class='text-left text-sm text-warm-gray-700'> {{Language.PATIENT_NAME_TITLE}} </label>
                            <div class='flex font-medium text-lg'>
                            <div class="m-1">
                                <div class=''>{{store.state.patientFirstName}}</div>
                            </div>
                            <div class="p-1">
                                {{store.state.patientMiddleInitial}}
                            </div>
                            <div class="m-1">
                                {{store.state.patientLastName}}
                            </div>
                            </div>
                    </div>
                    <div class='col-span-1  pt-6 items-left text-left'> 
                            <label class='text-left text-sm text-warm-gray-700'> Birthday </label>
                            <div class='flex font-medium text-lg'>
                                <div class="m-1">
                                <div class='text-warm-gray-900'>{{store.state.patientBirthday}} </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </template>
        </two-button-wide-modal>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore, mapState} from "vuex";
import {useRouter} from "vue-router";
import PatientDetailsAndForm from '../../../components/apps/common/PatientDetailsAndForm.vue';
import FormMini from "./../../../components/apps/common/dose/FormMini.vue";
import StatusMini from "./../../../components/apps/common/dose/StatusMini.vue";
import VerticalTimeline from "./../../../components/apps/provider/VerticalTimeline.vue";
import account from "./../../../apis/account.js";
import LiteMenu from '../../../components/apps/lite/LiteMenu.vue';
import TwoButtonWideModal from "./../../../components/tailwind/modals/TwoButtonWide.vue"
import Language from "./../../../constants/language/english.js";
import Run from "run-sdk";
import AccountSampleKeys from "./../../../constants/AccountSampleKeys.js";
import DoseDetailsMini from "./../../../components/apps/provider/DoseDetailsMini.vue";
import VXConstants from './../../../constants/VXConstants.js';
import VXVaccineConstants from "./../../../constants/VXVaccineConstants.js";
import VXTransactionHelper from "./../../../helpers/VXTransactionHelper.js";
import Utilities from "./../../../helpers/Utilities.js";
import ApplicationConstants from '../../../constants/ApplicationConstants';
import Footer from '../../../components/apps/lite/Footer.vue';
export default {
    components:{
        PatientDetailsAndForm,
        FormMini,
        VerticalTimeline,
        StatusMini,
        LiteMenu,
        TwoButtonWideModal,
        DoseDetailsMini,
        Footer,
    },
    async setup () {
        let store = useStore();
        let router = useRouter();
        let _seed = "";
        if(store.state.liteSeed.length > 0){
            _seed = store.state.liteSeed;
        }
        const fetchRedeemToken = async () =>{
            let address = account.addressFromSeed(_seed, ApplicationConstants.PATIENT_OWNER_PATH, null)
            console.log(address);
            let run = new Run({network: "test", owner: address, trust: "*"})
            await run.inventory.sync();
            console.log(run.inventory.jigs);
            let _token = run.inventory.jigs.find(jig => jig.factory && jig.factory.origin === "856af2ff416783bfd4c02347e360b975f590dcf6f0ef034d451b3577e1c8b1ab_o3")
            return _token;
        }
        let token = await fetchRedeemToken();
        if(!token || token.length < 1){
            router.push('/apps/lite/home');
        }
        console.log({token})
        const state = reactive({
            seed: _seed,
            editPatientDetails: false, 
            showSendButton: false,
            emptyFields: [],
            showModal: false,
            showSeedInput:false,
        })
    
        return {
            token,
            store,
            Language,
            fetchRedeemToken,
            ...toRefs(state),
        }
    },
    mounted(){
        console.log("mounted");
        if(this.$store.state.seedLite){this.setSeed()}
    },
    methods:{
        setSeed(){
            console.log(this.seed);
            let pk = account.privateKeyFromSeed(this.seed, ApplicationConstants.PATIENT_OWNER_PATH, null);
            let address = account.addressFromSeed(this.seed, ApplicationConstants.PATIENT_OWNER_PATH, null);
            this.$store.commit("setLiteSeed", this.seed);
            let options = {_seed: this.seed, _loginType: ApplicationConstants.LOGIN_TYPE_PATIENT}
            this.$store.dispatch("login", options)
            this.$store.commit("setPatientPrivKey", pk);
            this.$store.commit("setPatientAddress", address);
        },
        async clearSeed(){
            this.showSeedInput = true;
            // this.$store.commit("setLiteSeed", "");
            // await this.$store.dispatch("clearPatientPersonalData")
        },
        patientInfoComplete(){
            let complete = true; 
            let response = [];
            console.log(this.$store.state.patientAddress);
            if(this.$store.state.patientAddress.length < 1){
                let addr = account.addressFromSeed(this.seed, ApplicationConstants.PATIENT_OWNER_PATH, false);
                this.$store.commit("setPatientAddress", addr);
                if(addr.length < 1)
                    complete = false; response.push("Address")
            }
            if(this.$store.state.patientFirstName.length === 0){complete = false; response.push("First Name"); this.editPatientDetails = true;}
            if(this.$store.state.patientMiddleInitial.length === 0){complete = false; response.push("Middle Initial"); this.editPatientDetails = true;}
            if(this.$store.state.patientLastName.length === 0){complete = false; response.push("Last Name"); this.editPatientDetails = true;}
            if(!this.$store.state.patientBirthday){complete = false; response.push("Birthday"); this.editPatientDetails = true;}
            this.emptyFields = response;
            if(!complete){this.showSendButton = false; return response}
            this.showSendButton  = true;
            return complete;
        },
        patientSaved(){
            console.log("After Click:", this.patientInfoComplete());
            this.editPatientDetails = false;
        },
        showPatientEdit(){
            this.editPatientDetails = true;
            this.showSendButton = false;
        },
        hidePatientEdit(){
            this.editPatientDetails = false;
            this.patientInfoComplete();
        },
       async savePatientInfo(details){
            console.log("Patient Details:", details)
            if(this.patientInfoComplete()){
                await this.$store.dispatch("updatePatientDetails", details);
            }
        },
        cancelClicked(){
            this.showModal = false;
        },
        sendButtonClick(){
            console.log("send button click")
            this.showModal = true;
        },
        
        async confirmClicked(){
            let pk = account.privateKeyFromSeed(this.seed, ApplicationConstants.PATIENT_OWNER_PATH, false);
            let to = this.$store.state.patientAddress;
            let _run = new Run({trust:"*", network: "test", owner:pk , purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY})
            _run.activate();
            await _run.inventory.sync()
            console.log(_run.inventory.jigs)
            let _token = _run.inventory.jigs.find(jig => jig.factory && jig.factory.origin === "856af2ff416783bfd4c02347e360b975f590dcf6f0ef034d451b3577e1c8b1ab_o3")
            await _token.sync();
            let _class = await _run.load(VXConstants.VACCINATION_RECORD_CLASS_LOCATION);
            let _ndc1 = VXVaccineConstants.VACCINE_NDC_CODES_1[this.$store.state.dose1Manufacturer];
            let _ndc2 = VXVaccineConstants.VACCINE_NDC_CODES_2[this.$store.state.dose2Manufacturer];
            //let options1 = {manufacturer: this.$store.state.dose1Manufacturer, ndc: _ndc, lot: this.$store.state.dose1LotID, expiry: Utilities.dateString(+365), distributed: this.$store.state.dose1DistributedDate, site: "", firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
           
            await _class.sync()
            let sig1 = VXTransactionHelper.signRecord(_run, this.dose1Manufacturer, _ndc1, this.dose1LotID, Utilities.dateString(+365), this.dose1DistributedDate);
            let sigwName = VXTransactionHelper.signWithName(_run, this.dose1Manufacturer, _ndc1, this.dose1LotID, Utilities.dateString(+365), this.dose1DistributedDate, this.patientFirstName, this.patientMiddleInitial, this.patientLastName);
            let sig2 = VXTransactionHelper.signRecord(_run, this.dose2Manufacturer, _ndc2, this.dose2LotID, Utilities.dateString(+365), this.dose2DistributedDate);
            let sigwName2 = VXTransactionHelper.signWithName(_run, this.dose2Manufacturer, _ndc2, this.dose2LotID, Utilities.dateString(+365), this.dose2DistributedDate, this.patientFirstName, this.patientMiddleInitial, this.patientLastName);
            try{
                let tx = new Run.Transaction()
                tx.update(() => _token.destroy())
                tx.update(() => new _class(null, to, this.dose1Manufacturer, _ndc1, this.dose1LotID, Utilities.dateString(+365), this.dose1DistributedDate,"", sig1, sigwName))
                tx.update(() => new _class(null, to, this.dose2Manufacturer, _ndc2, this.dose2LotID, Utilities.dateString(+365), this.dose2DistributedDate,"", sig2, sigwName2))
                console.log(await tx.publish());
                this.showModal = false;
                this.$store.commit("setPatientSeed", this.$store.state.liteSeed);
                let _params = {_seed: this.$store.state.liteSeed, _loginType: ApplicationConstants.LOGIN_TYPE_PATIENT}
                await this.$store.dispatch("login", _params);
                //this.$store.commit("setLiteSeed", "");
                this.$router.replace("/apps/patient/keys");
            }catch(err){alert(err); console.log(err); this.showModal = false}
           // this.$store.dispatch("clearPatientPersonalData");
        },

        doseDetails(index){
            if(index === 1){
                return `Distributed: ${this.dose1DistributedDate}
                lot#: ${this.dose1LotID} `
            }else{
                return `Distributed: ${this.dose2DistributedDate}
                lot#: ${this.dose2LotID} `
            }
        },
        undo(){
            this.showSeedInput = false;
        }
    },
    computed:{
        firstDoseStatus(){
            return "staged"
        },
        secondDoseStatus(){
            return "staged"
        },
        steps(){
            return [
            // { name: 'Dose Info', description: 'All Information about patient doses must be complete.', href: '#', status: 'complete' },
            { name: `
            `, description: '', href: '#', status: 'button' },
            ]
        },
        ...mapState(['dose1Manufacturer', 'dose2Manufacturer', 'dose1DistributedDate', 'dose2DistributedDate', 'patientFirstName', 'patientMiddleInitial', 'patientLastName', 'patientBirthday', 'patientStateID', 'patientPassportNumber', "dose1LotID", "dose2LotID"])
    }
}
</script>

<style lang="scss" scoped>

</style>