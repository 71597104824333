<template>
<div class="text-left h-auto p-2 font-medium text-warm-gray-600 space-y-1 bg-white shadow-xl rounded" style="z-index:99">
    <div class="flex">
        <div class="flex-grow font-semibold text-gray-700">
            <i class="fa fa-hospital-user"></i> 
            <span class='text-lg'> {{Language.PATIENT_DETAILS}} </span>
        </div>
        <div v-if='!showEdit' class='flex'>
            <button class='text-left' @click="showEditFunction()"> Edit </button>
        </div>  
    </div>
     <div class='flex space-x-1 ml-2'>
            {{firstName}}
            {{middleInitial}}
            {{lastName}}
        </div>
           <div class='flex space-x-1 ml-2'>
            {{birthday}}
        </div>
    <span class='text-xs text-gray-800 break-all'> {{store.state.patientAddress}} </span>
    <hr />
    <div class="grid grid-cols-2 md:grid-cols-1">
        <div> 
            <div>
                <div v-if="showEdit">
                    <div class="mt-1" v-if="showAddress !== false">
                        <input v-model="patientAddress" type="text" name="patientAddress" id="patientAddress" class=" max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="xcty..." />
                    </div>
                    <div class="mt-1">
                        <label> First Name </label>
                        <input v-model="firstName" type="text" name="firstName" id="firstName" class=" max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="Jane" />
                    </div>
                </div>
                <div v-else>
                    <label class='text-xs text-gray-700'> {{Language.PATIENT_NAME_TITLE}} </label>
                    <div class='flex space-x-1 ml-2'>
                        <label class="text-xs sm:text-md"> {{firstName}} </label>
                        <label class="text-xs sm:text-md"> {{middleInitial}} </label>
                        <label class="text-xs sm:text-md"> {{lastName}} </label>
                    </div>
                </div>
            </div>
            
            <div>
                <div v-if="showEdit">
                    <div class="mt-2">
                        <label> Middle Initial </label>
                        <input v-model="middleInitial" type="text" name="middleInitial" id="middleInitial" class=" max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="Q" />
                    </div>
                    <div class="mt-2">
                        <label> Last Name </label>
                        <input v-model="lastName" type="text" name="lastName" id="lastName" class=" max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="Doe" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div v-if="showEdit">
                <div class="pt-1">
                    <label class="text-xs sm:text-md"> Birthday </label>
                    <input v-model="birthday" type="date" name="birthday" id="birthday" class="max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="Doe" />
                </div>
            </div>
            <div v-else>
                <label class='text-xs text-gray-700'> {{Language.BIRTHDAY}} </label>
                <div> 
                <label class='text-xs ml-2 sm:text-md'> {{ birthday }} </label>
                </div>
            </div>
        </div>

        <div v-if="includeIds">
            <div v-if="showEdit">
                <div class="pt-1">
                    <input v-model="stateID" type="text" name="stateID" id="stateID" class="max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="State ID or DL" />
                </div>
            </div>
            <div v-else>
                <label class='text-xs text-gray-700'> {{Language.STATE_ID}} </label>
                <div class='ml-2'> {{stateID ? stateID : "No State ID"}} </div>
            </div>
        </div>

        <div v-if="includeIds">
            <div v-if="showEdit">
                <div class="pt-1">
                    <input v-model="passportNumber" type="text" name="passportNumber" id="passportNumber" class="max-h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full" placeholder="Passport #" />
                </div>
            </div>
            <div v-else>
                <label class='text-xs text-gray-700'> {{Language.PASSPORT_NUMBER}} </label>
                <div class='ml-2'> {{passportNumber ? passportNumber : "No Passport Number"}} </div>
            </div>
        </div>

        
    </div>
    <div class="space-y-2 flex-1 w-full">
        <div v-if="showEdit" class='flex-grow'>
            <button @click="save" class="w-full h-10 text-lg bg-indigo-500 rounded text-white">Save</button>
        </div>
        <div v-if="showEdit" class='flex-grow'>
            <button @click="cancel" class="w-full h-10 text-md rounded text-red-800">Cancel</button>
        </div>
       
        </div>
</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore} from "vuex";
import Language from "./../../../constants/language/english.js"
export default {
  components: { },
    setup () {
        const store = useStore();
        const state = reactive({
            patientAddress: store.state.patientAddress,
            firstName: store.state.patientFirstName,
            middleInitial: store.state.patientMiddleInitial,
            lastName: store.state.patientLastName,
            birthday: store.state.patientBirthday,
            stateID: store.state.patientStateID,
            passportNumber: store.state.patientPassportNumber,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods:{
        async save(){
            if(this.isValid()){
                let details = {};
                details.address = this.patientAddress;
                details.firstName = this.firstName;
                details.middleInitial = this.middleInitial;
                details.lastName = this.lastName;
                details.birthday = this.birthday;
                details.stateID = this.stateID;
                details.passportNumber = this.passportNumber;
                await this.savePatientInfo(details);
                this.$emit('patient-saved');

            }
            else {alert("Please fill out all the required information");}
        },
        cancel(){
            this.close();
        },
        isValid(){
            let valid = true; 
            if(this.firstName === "" || !this.firstName){valid = false}
            if(this.middleInitial === "" || !this.middleInitial){valid = false}
            if(this.lastName === "" || !this.lastName){valid = false}
            if(this.birthday === "" || !this.birthday){valid = false}
            return valid;
        }
    },
    computed:{
        showEdit(){
            if(this.edit){return true}
            return false;
        },
        store(){
            return this.$store
        }
    },
    props:["edit", "savePatientInfo", "showEditFunction", "close", "includeIds", "showAddress"]
}
</script>

<style  scoped>

</style>