<template>
    <div class="grid grid-cols-2 items-center items-justify-center"> 
        <div class='col-span-1 ml-auto pr-1'>
            <i :class="statusClasses"> </i>
        </div>
        <div class="col-span-1 mr-auto pl-1">
            <p class='text-2xl'> 
            {{titleText}}
            </p>
            {{Language.DOSE}}
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Language from "./../../../../constants/language/english.js";

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
        return {
            Language,
            ...toRefs(state),
        }
    },
    computed:{
        statusClasses(){
            console.log(this.status)
            let classes = "fa fa-syringe text-3xl";
            if(this.status === null || this.status === "unknown"){
                classes += " text-warm-gray-500";
            } else if (this.status === "staged"){
                return classes += " text-indigo-600";
            }
            return classes; 
        },
        titleText(){
            console.log("Dose Type:", this.doseType)
            if(this.doseType === "first"){return Language.FIRST_NUMBER}
            if(this.doseType === "second"){return Language.SECOND_NUMBER}
            return ""
        }
    },
    props:['status', "doseType"]
}
</script>

<style lang="scss" scoped>

</style>