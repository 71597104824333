<template>
    <logged-in>
        <template v-slot:content>
            <div class="h-full mx-auto rounded md:mt-8 shadow p-2 max-w-md">
                <div class="bg-white">
                    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl text-center">
                        <div class='flex-col text-white pt-6 pb-10'>
                            <div class="text-4xl font-semibold text-gray-500">
                                Your code
                            </div>
                                <div class="text-sm font-normal pb-4 text-gray-500">
                                Present this code to scan your account. 
                            </div>
                            <!-- <div class='flex items-center pb-4'> 
                                <div class="flex-grow text-right pr-0 mr-0  ">
                                    <span class="tracking-tight font-bold text-6xl bg-clip-text text-transparent bg-pink-500 md:text-7xl">
                                        VX
                                    </span>  
                                </div>
                                <div class="pl-0 ml-0 tracking-tighter font-regular text-6xl bg-clip-text text-transparent bg-pink-500 md:text-7xl ">
                                    PASS
                                </div>
                                    <div class="flex-grow text-left pl-1 ml-0 font-bold text-6xl bg-clip-text text-transparent tracking-tight bg-gradient-to-r from-purple-800 to-indigo-500 md:text-7xl ">
                                        ID
                                    </div>
                            </div> -->
                            <!-- <div class='mx-auto'>
                            <VXPassSVG />
                            </div> -->
                            <div class='pb-10 mb-10 pl-6'> 
                            <QRWrapper>
                                 <div class="-pl-4 -ml-4 pt-2" v-if="showId">
                                <PatientQR class="" v-if="showId ||  tokens.length < 2 || tokens[0].mfg === VXVaccineConstants.VACCINE_MANUFACTURERS['Janssen']"/>
                                </div>
                                <div class="-pl-4 -ml-4" v-if="isComplete && !showId">
                                    <BadgeCheckIcon v-if="isComplete && !showId" class='text-green-400 h-48 w-48 mx-auto my-auto' />
                                </div>
                            </QRWrapper>
                            </div>
                            <div>
                                <button v-if="showCheck" @click="checkRecords(store)" class="p-2 m-2 bg-green-400 text-white rounded-xl shadow"> 
                                    <div v-if="!searching" class='flex'>
                                        <CheckIcon class='h-6 w-6'/> 
                                        {{Language.CHECK_RECORDS}}
                                    </div>
                                    <div v-else class='flex'>
                                        <i class='fa fa-spinner animate-spin'></i>
                                        {{Language.CHECK_RECORDS}}
                                    </div>
                                </button>
                            </div>
                            <div v-if='tokens.length < 2' class='mx-auto xs:items-center'>
                                <button class="bg-green-400 p-2 m-2 text-xl rounded-xl" @click="showId = !showId"> {{showId ? Language.HIDE : Language.SHOW}} {{Language.QR}}</button>
                            </div>
                            <div class="text-gray-300 pt-10 pb-10">
                                <div class='text-lg font-semibold'>
                                    {{Language.PATIENT_ID}}
                                </div>
                                <Address />
                            </div>
                        </div>
                        <!-- <div class="mt-3 pt-4 grid gap-2 flex">
                            <div class=' flex-grow p-2 m-2'> 
                                <card-simple headerText="Covid" buttonText="DoSomething" :showRightText="true" rightText="Dose 1" rightTextColor="text-indigo-600" extraClasses="ring-1 ring-indigo-600  shadow"> 
                                    <template v-slot:content>
                                        <div v-if="tokens.length === 0">
                                            No Records Found
                                        </div>
                                        <div v-else>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.MANUFACTURER}}: </label>
                                                <div class="text-xl flex-grow"> {{tokens[0].mfg}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.DISTRIBUTED_DATE}}: </label>
                                                <div class="text-lg flex-grow"> {{tokens[0].distributed}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.IS_VERIFIED}}: </label>
                                                <div class="text-lg flex-grow"> {{checkVerify(tokens[0])}} </div>
                                            </div>
                                        </div>
                                    </template>
                                </card-simple>
                            </div>
                            <div v-if="tokens.length !== 0 && tokens[0].mfg !== VXVaccineConstants.VACCINE_MANUFACTURERS['Janssen']" class='p-2 m-2'> 
                                <card-simple headerText="Covid" buttonText="DoSomething" :showRightText="true" rightText="Dose 2" rightTextColor="text-pink-700" extraClasses="ring-1 ring-pink-500 shadow"> 
                                    <template v-slot:content>
                                        <div v-if="tokens.length === 0">
                                            No Records Found
                                        </div>
                                        <div v-else>
                                        <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.MANUFACTURER}}:</label>
                                                <div class="text-xl flex-grow">{{tokens[1].mfg}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.DISTRIBUTED_DATE}}: </label>
                                                <div class="text-lg flex-grow"> {{tokens[1].distributed}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.IS_VERIFIED}}: </label>
                                                <div class="text-lg flex-grow"> {{checkVerify(tokens[1])}} </div>
                                            </div>
                                        </div>
                                    </template>
                                </card-simple>
                            </div>
                        </div> -->
                    </div>
                    <div v-if="tokens.length > 0" class='grid grid-cols-2'>
                        <div v-for="token in tokens" :key='token.location' class="bg-white col-span-1 shadow-2xl rounded-xl m-1 ring-1 ring-green-500">
                            <dose-details :token="token"></dose-details>
                        </div>
                    </div>
                     <div class="">
                                <img ref="picture" />
                            </div>
                    <div class="mx-auto pt-10">
                        <status-bar :steps="steps"></status-bar>
                    </div>
                </div>
            </div>
        </template>
    </logged-in>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import {BadgeCheckIcon, CheckIcon} from "@heroicons/vue/outline"
import {useStore} from "vuex";
import LoggedIn from "./layouts/LoggedIn.vue"
//import CardSimple from "./../../../components/tailwind/cards/CardSimple.vue"
import Run from "run-sdk";
import Utilities from "./../../../helpers/Utilities.js"
import PatientQR from "./../../../components/apps/patient/status/PatientQR.vue"
import Address from "./../../../components/apps/patient/profile/Address.vue"
import Language from './../../../constants/language/english.js'
import VXVaccineConstants from "./../../../constants/VXVaccineConstants.js";
import QRWrapper from '../../../components/apps/common/QRWrapper.vue';
import StatusBar from '../../../components/apps/patient/status/StatusBar.vue';
import DoseDetails from '../../../components/apps/patient/status/DoseDetails.vue';

//import VXPassSVG from "./../../../components/apps/common/svgs/VXPass.vue"


export default {
    async setup () {
       let store = useStore();
       let searching = ref(false);
        
       let tokens = ref([]);
        const initCheck = async (_store) =>{ 
            //const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })
            console.log("PatientSeed:", _store.state.patientSeed)
            console.log("PatientSeed:", _store.state.patientLiteSeed)
            console.log("Patient Private key", _store.state.patientPrivKey)
            let run = new Run({network: "test", trust:"*", owner: _store.state.patientPrivKey, networkTimeout: 20000})
            await run.activate();
            try{
                console.log("syncing");
                await run.inventory.sync()
            }catch(err){
                console.log(err)
            }
            console.log("synced");
            let tokens = Utilities.findRecords(run);
            if(tokens.length > 0 ){
                _store.commit("resetPatientTokens")
                _store.commit("setPatientTokens", tokens)
            }
            return tokens;

        }
        
        const _showId = (tokens.value && tokens.value.length < 2) 
        const checkVerify = Utilities.verifyRecordSig;
        tokens = await initCheck(store);
        const state = reactive({
            tokens: tokens,
            showId: _showId,
            searching: false,
        })
        return {
            Language,
            searching,
            initCheck,
            checkVerify,
            VXVaccineConstants,
            ...toRefs(state),
            store,
        }
    },
    computed:{
        isComplete(){
            console.log(this.tokens.length);
            let response = false;
            if(this.tokens.length >= 2 ){response = true}
            if(this.tokens.length === 1){
                if(this.tokens[0].mfg === VXVaccineConstants.VACCINE_MANUFACTURERS['Janssen']){
                    response = true;
                }
            }
            console.log(response)
            return response;
        },
        steps(){
            let dSteps =  [];
            if(this.tokens.length === 0){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'current' },
                    { name: 'Step 2', stepType: '#', status: 'current' },
                    { name: 'Step 3', stepType: '#', status: 'current' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }else if(this.tokens.length === 1){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'complete' },
                    { name: 'Step 2', stepType: '#', status: 'current' },
                    { name: 'Step 3', stepType: '#', status: 'current' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }else if(this.tokens.length >= 2){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'complete' },
                    { name: 'Step 2', stepType: '#', status: 'complete' },
                    { name: 'Step 3', stepType: '#', status: 'complete' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }

          
            return dSteps;
        },
        showCheck(){
            if (this.tokens && this.tokens.length < 2) return true;
            return false;
        }
    },
    async mounted() {
        console.log("mounted")
         const isLoggedIn = () => {
            if(this.$store.state.patientPrivKey === "" || this.$store.state.patientSeed === ""){
                return false;
            }
            return true;
        }
        if(!isLoggedIn())
            this.$router.push('/apps/patient/login');
        if(this.tokens.length === 0){
            await this.initCheck(this.$store);
        }
        let file =  localStorage.card_file2;
        console.log(file)
        if(file)
            this.$refs.picture.src = file;
        
    },
    methods:{
        async checkRecords(){
            this.searching = true;
            this.tokens = await this.initCheck(this.store);
            this.searching = false;

        }
    },
    components:{
        LoggedIn,
        //CardSimple,
        PatientQR,
        Address,
        CheckIcon,
        BadgeCheckIcon,
        QRWrapper,
        StatusBar,
        DoseDetails,
        //VXPassSVG
    }
}
</script>

<style lang="scss" scoped>

</style>