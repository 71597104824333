<template>
    <div class='flex-1 space-y-2'>
        <div v-if="redeemTokenFactory"> {{redeemTokenFactory.name}}</div>
        <div class="">
            <button @click="createRedeemTokenFactory('Redeem Token Factory')">Create Redeem Token Factory </button>
        </div>
        <div class='space-y-10'> </div>
        <div class="">
            <button @click="createRedeemTokenFromFactory(randomAddress)">Create Token </button>
        </div>
        <div class='space-y-10'> </div>
        <div class="">
            <button @click="clearPatientDetails">Clear Patient Details </button>
        </div>

        {{store.state.patientSeed}}<br/>
        {{store.state.patientPrivKey}}<br/>
        {{store.state.patientAddress}}<br/>
        {{store.state.liteSeed}}<br/>

        <div class="flex-1 ">
            <div class="">
            {{store.state.network}}
            </div>
            <div class="">
            <button @click="setNetwork(ApplicationConstants.TEST_NETWORK)">Change to Test </button>
            </div>
            <div class="">
            <button @click="setNetwork(ApplicationConstants.LIVE_NETWORK)">Change to LIVE </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import {useStore} from "vuex"
import Run from "run-sdk"
//import bsv from "bsv"
import AccountSampleKeys from '../../../constants/AccountSampleKeys.js';
import ApplicationConstants from '../../../constants/ApplicationConstants.js';

let Token = Run.extra.Token;
export class RedeemTokenFactory extends Run.Jig{
    init(name){
        this.name = name;
    }
    createRedeemToken(to){
        return new RedeemToken(to)
    }
}
export class RedeemToken extends Token {
    init(to){
        this.burned = false 
        this.owner = to
        // eslint-disable-next-line no-undef
        this.factory = caller;
    }
}

RedeemTokenFactory.deps = {RedeemToken};

export default {
    setup () {
        let redeemTokenFactory = ref(null);
        let run = new Run({network: "test"})
        let store = useStore();
        const state = reactive({
            count: 0,
            randomAddress: run.owner.address,
        })
    
        return {
            store,
            redeemTokenFactory,
            ApplicationConstants,
            ...toRefs(state),
        }
    },
    methods:{
        async createRedeemTokenFactory(name){
            let run = new Run({network: "test", owner:AccountSampleKeys.ADMIN_OWNER_PRIVATE_KEY, purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, trust: "*"})
            run.activate();
            await run.inventory.sync();
            let tx = new Run.Transaction();
             tx.update(() => new RedeemTokenFactory(name));
            let response = await tx.publish();
            console.log(response);
            return response; 
        },
        async createRedeemTokenFromFactory(to){
            let run = new Run({network: "test", owner:AccountSampleKeys.ADMIN_OWNER_PRIVATE_KEY, purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, trust: "*"})
            await run.inventory.sync();
            let _factory = null;
            run.inventory.jigs.forEach(j => {
                if(j.origin === "856af2ff416783bfd4c02347e360b975f590dcf6f0ef034d451b3577e1c8b1ab_o3"){
                    _factory = j;
                }
            })
            await _factory.sync();
            console.log(_factory, to);
            
            let response = await _factory.createRedeemToken(to);
            console.log(response)
        },
        async clearPatientDetails(){
            await this.$store.dispatch("clearPatientPersonalData");
            await this.$store.dispatch("clearPatientDoseData");
        },
        setNetwork(_net){
            this.$store.commit("setNetwork", _net)
        }
    },
    computed:{
            changeNetworkButtonText(){
                if(this.$store.state.network === ApplicationConstants.TEST_NETWORK){
                    return "Live"
                }
                return "Test"
            }
        }
}
</script>

<style lang="scss" scoped>

</style>