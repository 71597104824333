import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import QrReader from 'vue3-qr-reader';
import "tailwindcss/tailwind.css"

createApp(App).use(store).use(router).use(QrReader).mount('#app')

//const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })

//const blockchain = new Run.plugins.MatterCloud({ apiKey: '...' })