<template>
    <div>
        <!-- <div v-if="XS || SM">
             <LoggedIn >
                <template v-slot:welcomePanel> 
                    <div class="rounded-lg bg-white overflow-hidden shadow">
                        <h2 class="sr-only" id="profile-overview-title">Covid-19</h2>
                        <div class="bg-white p-6">
                        <div class="sm:flex sm:items-center sm:justify-between">
                            <div class="sm:flex sm:space-x-5">
                            <div class="flex-shrink-0">
                                <img class="mx-auto h-20 w-20 rounded-full" src="" alt="" />
                            </div>
                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p class="text-sm font-medium text-gray-600">Covid-19</p>
                                <p class="text-xl font-bold text-gray-900 sm:text-2xl">Point of Care</p>
                                <p class="text-sm font-medium text-gray-600">Vaccination Record</p>
                            </div>
                            </div>
                            <div class="mt-5 grid grid-cols-3 justify-center sm:mt-0">
                                <div class='col-span-2'></div>
                                <div class='col-span-1 '> 
                                    <button @click="createPatient" type="button" class="m-1 p-1  w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ring-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                                        Create Patient
                                    </button>
                                </div>
                                <div class='pt-2 col-span-2 '>
                                    <input v-model="patientAddress" class='min-w-sm w-full rounded shadow' />
                                    
                                </div>
                                <div class="col-span-1">
                                    <button type="button" class="m-1 p-1 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                                        Set Address
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </template>
            </LoggedIn>
        </div> -->
        <div v-if="false" class='h-auto'>
            Tablet
            <div class='h-full'>
                <div class='top'>T</div>
                <div class='h-full'>x</div>
                <div class='bottom'>B</div>
            </div>
        </div>
        <div v-if="XS || SM || MD || LG || XL">
            <LoggedIn >
                <template v-slot:welcomePanel> 
                    <div class="rounded-lg bg-white overflow-hidden shadow">
                        <h2 class="sr-only" id="profile-overview-title">Covid-19</h2>
                        <div class="bg-white p-6">
                        <div class="sm:flex sm:items-center sm:justify-between">
                            <div class="sm:flex sm:space-x-5">
                            <div class="flex-shrink-0">
                                <img class="mx-auto h-20 w-20 rounded-full" src="./../../../assets/images/radial-logo.png" alt="" />
                            </div>
                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p class="text-sm font-medium text-gray-600">Covid-19</p>
                                <p class="text-xl font-bold text-gray-900 sm:text-2xl">Point of Care</p>
                                <p class="text-sm font-medium text-gray-600">Vaccination Record</p>
                            </div>
                            </div>
                            <div class="mt-5 grid grid-cols-3 justify-center sm:mt-0">
                               <SetPatientAddress />
                            </div>
                        </div>
                        </div>
                  <div class="visible h-auto lg:invisible lg:h-0 rounded-lg bg-white overflow-hidden shadow">
                 <button class='w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500' @click="hideQR = !hideQR"> <i :class="hideQR ? 'far fa-eye': 'fa fa-eye-slash'"></i> {{hideQR ? "Show Reader": "Hide Reader"}}</button>
                </div>
                <div v-if="!hideQR" class="bg-gradient-to-r from-pink-500 to-indigo-600 rounded-full  lg:invisible lg:h-0">
                    <div class=" p-1 m-1">
                        <div  class="visible h-auto lg:invisible lg:h-0 rounded-full overflow-hidden shadow">
                            <div class="center ">
                                <qr-stream @decode="onDecode" class="mb rounded-full">
                                    <div style="color: red;" class="frame"></div>
                                </qr-stream>
                            </div>
                            <qr-capture @decode="onDecode" class="mb p-4 m-4 shadow"></qr-capture>
                            <!-- <div class="result text-white">
                                Scanned: 
                                <div> 
                                    {{data ? data["firstName"] : "" }} {{data ? data["middleInitial"] : "" }} {{data ? data["lastName"] : "" }}
                                </div>
                                <div> {{formedBirthday}}</div>
                            </div> -->
                        
                        </div>
                    </div>
                </div>
            
                <div  class="m-6 p-6 grid grid-cols-3 gap-y-6 sm:grid-cols-3 sm:gap-x-8 shadow">
                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
                        <input v-model="firstName" type="text" name="firstName" id="firstName" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="middleInitial" class="block text-sm font-medium text-gray-700">Middle</label>
                        <input v-model="middleInitial" type="text" name="middleInitial" id="middleInitial" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="lastName" class="block text-sm font-medium text-gray-700">LastName</label>
                        <input v-model="lastName" type="text" name="lastName" id="lastName" autocomplete="postal-code" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>
                     <div class="flex rounded-md shadow-sm w-full col-span-3 lg:col-span-3 sm:col-span-2 ">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm sm:px-1">
                            BSV Address:
                        </span>
                        <input v-model="address" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                    </div>
                    <div class="sm:col-span-2 sm:flex sm:justify-end w-full">
                    <button @click="checkForTokensToVerify" type="" class="mt-2 h-12 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto">
                        Submit
                    </button>
                    </div>
                    
                </div>
                
                    </div>
                    
                </template>
                <template v-slot:rightColumn >
                   <section aria-labelledby="announcements-title">
                        <div class="rounded-lg bg-white overflow-hidden shadow">
                            <div class="p-6">
                                <h2 class="text-base font-medium text-gray-900" id="announcements-title">Status</h2>
                                <div class="flow-root mt-6">
                                    <div v-if="!tokens.length || tokens.length === 0">There are no tokens to display. </div>
                                    <div> {{tokens.length }}</div>
                                    <ol> 
                                        <li v-for="token in tokens" :key="token.location">
                                            <div v-if="checkVerify(token)" class='shadow rounded-xl'> 
                                                <div class="flex">
                                                    <div class='flex flex-1 p-2 m-2'> 
                                                        <CheckCircleIcon class="max-h-12 max-w-12 text-green-400"/>  
                                                        <div class="flex flex-col">
                                                        <div class="text-left"> {{token.mfg}} </div>
                                                        <div> {{new Date(token.distributed).toLocaleDateString("en-US")}} </div>
                                                        </div>
                                                    </div>
                                                    <div class='flex flex-1 p-2 m-2'> 
                                                        <div v-if="checkVerifySig(token)" > 
                                                            <div class="flex flex-col">
                                                                <div class='flex flex-1 items-center justify-center'> 
                                                                    <CheckCircleIcon class="max-h-12 max-w-12 text-green-400"/>  
                                                                    <div> Verified Name</div>
                                                                </div>
                                                            </div>
                                                            <div class='flex flex-1'> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div v-else class='text-red-800'> 
                                                            This name does not match the record
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else> 
                                                <div class='text-red-800'> 
                                                    This Record was modified from its original form
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="invisible rounded-lg bg-white overflow-hidden shadow lg:visible ">
                            <div v-if="!hideQR" class="bg-gradient-to-r from-pink-500 to-indigo-600 ">
                                <div class="p-1">
                                    <div  class="visible overflow-hidden shadow ">
                                        <div class="center ">
                                            <qr-stream @decode="onDecode" class="mb rounded-full">
                                                <div style="color: red;" class="frame"></div>
                                            </qr-stream>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <qr-capture @decode="onDecode" class="mb p-4 m-4 shadow"></qr-capture>
                        </div>
                         
                   </section>
                </template>
            </LoggedIn>
            
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import useBreakpoints from "./../../../composition/useBreakpoints.js"
import LoggedIn from './layouts/LoggedIn.vue'
import {useStore} from "vuex";
import VXConstants from "./../../../constants/VXConstants.js";
import Run from "run-sdk";
import {CheckCircleIcon} from "@heroicons/vue/outline"
import Utilities from "./../../../helpers/Utilities.js"
import SetPatientAddress from "./../../../components/apps/common/SetPatientAddress.vue"

export default {
    components: {
        LoggedIn, CheckCircleIcon, SetPatientAddress
    },
    setup () {
        let store = useStore();
        let {width, type} = useBreakpoints()
        const onDecode = async(data) => {
            if(!state.isReading){
                state.isReading = true; 
                console.log(data.toString());
                if(data.toString().length > 0){
                    state.data = JSON.parse(data);
                    if(state.data["firstName"]){state.firstName = state.data["firstName"]}
                    if(state.data["middleInitial"]){state.middleInitial = state.data["middleInitial"]}
                    if(state.data["lastName"]){state.lastName = state.data["lastName"]}
                    if(state.data["address"]){state.patientAddress = state.data["address"]}
                    await checkForTokensToVerify();
                    state.isReading = false;
                }
            }
        }
        const checkForTokensToVerify = async() =>{
            state.message = "Checking For Tokens..."
            state.tokens.value = [];
            console.log(state);
            if(state.address === ""){return alert("An ID (address) is required")}
            const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })
            let _run =  new Run({blockchain: blockchain, network:"test", trust:'*', owner: state.address})
            console.log(_run.owner.address);
            
            await _run.inventory.sync();
            console.log(_run);
            
            let _jigs = _run.inventory.jigs;
            console.log({_jigs})
            _jigs.forEach( jig => {
                if(jig.cred && jig.cred.constructor.origin === VXConstants.CREDENTIAL_CLASS_ORIGIN){
                    console.log("This is a VXRecord");
                    state.tokens.push( jig)
                }
            });
            state.message = "VXPASS Records:";
            if(state.tokens.length === 0 ){state.message = "There are no VXPASS Records to Verify"}
        }
        const state = reactive({
            screenWidth: width,
            screenSize: type,
            patientAddress: store.state.patientAddress,
            firstName: "",
            middleInitial: "",
            lastName: "",
            birthday: "",
            address: store.state.patientAddress,
            tokens: [],
            message: "",
            hideQR: false,
            data: null,
            isReading: false,
        })
    
        return {
            onDecode,
            checkForTokensToVerify,
            ...toRefs(state),
        }
    },
    methods:{
        setPatientAddress(){
            this.$store.commit("setPatientAddress", this.patientAddress)
        },
        checkVerify(_token){
            return Utilities.verifyRecordSig(_token);
        },
         checkVerifySig(_token){
            return Utilities.verifyRecordSigwName(_token, this.firstName, this.middleInitial, this.lastName);
        }
        
    },
    computed:{
        XS(){if (this.screenSize === "xs") return true; else {return false}},
        SM(){if (this.screenSize === "sm") return true; else {return false}},
        MD(){if (this.screenSize === "md") return true; else {return false}},
        LG(){if (this.screenSize === "lg") return true; else {return false}},
        XL(){if (this.screenSize === "xl") return true; else {return false}},
        formedBirthday(){
            if(!this.data)return "";
            if (!this.data.birthday) return "";
            let dateArr = this.data.birthday.split("-");
            return `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
        },
    }
}
</script>

<style  scoped>
.stream {
  max-height: 300px;
  max-width: 300px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
</style>