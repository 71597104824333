<template>
    <div>
        <logged-in-wide>
            <template v-slot:welcomePanel>
                <div class='bg-white rounded-xl shadow'>
                    <div class="p-2 sm:flex sm:items-center sm:justify-between">
                        <div class="sm:flex sm:space-x-5">
                            <div class="flex-shrink-0">
                                <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="user.imageUrl" alt="" /> -->
                            </div>
                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p class="text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.HISTORY}}</p>
                                <p class="text-sm font-medium text-gray-600">{{  }}</p>
                            </div>
                        </div>
                        <div class="mt-5 flex justify-center sm:mt-0">
                        <button @click="router.push('/apps/provider/keys')" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                            View Keys
                        </button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actionsPanel>
                <div class='w-full bg-white h-72 rounded-xl shadow flex items-center justify-center'>
                <div class="">
                    <div class="text-red-600"> Coming Soon  </div>
                </div>
                </div>
            </template>
        </logged-in-wide>   
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import LoggedInWide from './layouts/LoggedInWide.vue'
import Language from "./../../../constants/language/english.js"

export default {
  components: { LoggedInWide },
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    }
}
</script>

<style lang="scss" scoped>

</style>