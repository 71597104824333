module.exports = {
    createPatientQRData(_address, _firstName, _middeleInitial, _lastName, _birthday){
        let response = {}
        response.address = _address; 
        response.firstName = _firstName;
        response.middleInitial = _middeleInitial; 
        response.lastName = _lastName;
        response.birthday = _birthday;
        let returner = JSON.stringify(response);
        console.log(returner)
        return returner;
    }
}