<template>
    <div class="relative bg-white py-16 sm:py-24 lg:py-32">
          <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 class="text-base font-semibold tracking-wider text-indigo-600 uppercase">The Future Is Now</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Built on Blockchain
            </p>
            <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              VXPASS makes the most of the blockchain. All records can be verified without making additional requests of your office.
            </p>
            <div class="mt-12">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div v-for="feature in safeFeatures" :key="feature.name" class="pt-6">
                  <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-gradient-to-b from-indigo-300 to-indigo-600 rounded-md shadow-lg">
                          <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ feature.name }}</h3>
                      <p class="mt-5 text-base text-gray-500">
                        {{ feature.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/outline'


export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
        safeFeatures(){
            if(this.features){return this.features}
            else{
                return [
                    {
                        name: 'Push to Deploy',
                        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.',
                        icon: CloudUploadIcon,
                    },
                    {
                        name: 'SSL Certificates',
                        description: 'Qui aut temporibus nesciunt vitae dicta repellat sit dolores pariatur. Temporibus qui illum aut.',
                        icon: LockClosedIcon,
                    },
                    {
                        name: 'Simple Queues',
                        description: 'Rerum quas incidunt deleniti quaerat suscipit mollitia. Amet repellendus ut odit dolores qui.',
                        icon: RefreshIcon,
                    },
                    {
                        name: 'Advanced Security',
                        description: 'Ullam laboriosam est voluptatem maxime ut mollitia commodi. Et dignissimos suscipit perspiciatis.',
                        icon: ShieldCheckIcon,
                    },
                    {
                        name: 'Powerful API',
                        description:
                        'Ab a facere voluptatem in quia corrupti veritatis aliquam. Veritatis labore quaerat ipsum quaerat id.',
                        icon: CogIcon,
                    },
                    {
                        name: 'Database Backups',
                        description: 'Quia qui et est officia cupiditate qui consectetur. Ratione similique et impedit ea ipsum et.',
                        icon: ServerIcon,
                    },
                ]
            }
        }
    },
    props:['features']
}
</script>

<style lang="scss" scoped>

</style>