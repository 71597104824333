<template>
<lite-menu></lite-menu>
    <div>
        <button @click="showSecret"> Show my secret code </button>
        <div v-if="show" > {{seed}} </div>
       
        <div class="">
            <button @click="resetUser"> clear user info </button>
        </div>
         
    </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import {useRouter} from "vue-router";
import Mnemonic from "bsv/mnemonic";
import account from "./../../../apis/account.js"
import {useStore} from "vuex"
import Run from "run-sdk"
import AccountSampleKeys from '../../../constants/AccountSampleKeys.js';
import ApplicationConstants from '../../../constants/ApplicationConstants.js';
import LiteMenu from "./../../../components/apps/lite/LiteMenu.vue"


//const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })
export default {
    components:{LiteMenu},
    async setup () {
        let store = useStore();
        let router = useRouter();
        let hasFactory = ref(false);
        let factory = null;
        let _seed = "";
        console.log("liteSeed: ", store.state.liteSeed);
        if(store.state.liteSeed){ _seed = store.state.liteSeed;}
        else {_seed = Mnemonic.fromRandom().toString();}
        if(_seed !== store.state.liteSeed)
            store.commit("setLiteSeed", _seed)

         const createRedeemTokenFromFactory  = async (to) => {
            let _run = new Run({network: "test", owner:AccountSampleKeys.ADMIN_OWNER_PRIVATE_KEY, purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, trust: "*"})
            await _run.activate();
            await _run.inventory.sync();
            let _factory = null;
            _run.inventory.jigs.forEach(j => {
                if(j.origin === "856af2ff416783bfd4c02347e360b975f590dcf6f0ef034d451b3577e1c8b1ab_o3"){
                    _factory = j;
                }
            })
            await _factory.sync();
            console.log(_factory, to);
            
            let response = await _factory.createRedeemToken(to);
            return response;
        }

       
       // Create Burnable Redeem Token ---> 
        let address = account.addressFromSeed(_seed, ApplicationConstants.PATIENT_OWNER_PATH, false);
        store.commit("setPatientAddress", address);
        let response = await createRedeemTokenFromFactory(address);
        await response.sync();
        if(response.origin){
            router.replace("/apps/lite/create-record")
        }


        const state = reactive({
            count: 0,
            seed: _seed,
            show: false,
        })
    
        return {
            hasFactory, 
            factory,
            ...toRefs(state),
        }
    },
    methods:{
        showSecret(){
            console.log("settingSeed", this.seed)
            this.$store.commit("setLiteSeed", this.seed)
            this.show = !this.show
        },
        async resetUser(){
            await this.$state.dispatch("clearPatientPersonalData");
        },
        
        async createRedeemToken(){
            console.log(this.factory);
            let address = account.addressFromSeed(this.seed.toString(), "m/44'/0'/0'", "testnet")
             let run = new Run({network: "test", owner: AccountSampleKeys.ADMIN_OWNER_PRIVATE_KEY, purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, trust: "*"})
                run.activate();
                let rt = this.factory.createRedeemToken(address);
                await rt.sync()
                console.log(rt);
            //     //router.push("/apps/lite/create-record");
        },
    }
}
</script>

<style lang="scss" scoped>

</style>