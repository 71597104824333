<template>

  <div class="lg:grid lg:grid-cols-12 lg:gap-x-1 text-left">
    <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
      <nav class="space-y-1">
        <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? ' w-full bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
          <component :is="item.icon" :class="[item.current ? 'text-indigo-500 group-hover:text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
          <span class="truncate">
            {{ item.name }}
          </span>
        </a>
      </nav>
    </aside>

    <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div >
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">Create a New Credential Factory </h3>
              <p class="mt-1 text-sm text-gray-500">A Credential Factory is an authority that has the responsibility of validating &amp; verifying that the requirements for obtaining a credential have been satisfied. </p>
              <p class="mt-1 text-sm text-gray-500">Examples: State Medical Board, State Bar Assn., Liquor Licenses, etc... </p>
            </div>

            <div class="grid grid-cols-3 gap-6">
              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label for="company_website" class="block text-sm font-medium text-gray-700">
                  Name of The Credentialing Authority
                </label>
                <div class="mt-1 rounded-md shadow-sm flex">
                  <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm break-none">
                     Organization:
                  </span>
                  <input v-model="organization" type="text" name="organization" id="organization" autocomplete="organization" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                <label for="about" class="block text-sm font-medium text-gray-700"> 
                  About
                </label>
                <div class="mt-1">
                  <textarea v-model="description" id="description" name="description" rows="4" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  Give a description of the organization. 
                </p>
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-1">
                <label class="block text-sm font-medium text-gray-700">
                  Logo URL
                  <img :src="logoUrl" class="h-8 w-8" />
                </label>
                <div class="mt-1 flex items-center">
                  <div class="mt-1 rounded-md shadow-sm flex w-full">
                  <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm break-none">
                     Logo:
                  </span>
                  <input v-model="logoUrl"  type="text" name="logoUrl" id="LogoUrl" autocomplete="organization" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
                  <!-- <button type="button" class="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Change
                  </button> -->
                </div>
                
              </div>
                <div class="col-span-6 sm:col-span-3 lg:col-span-1">
                <label class="block text-sm font-medium text-gray-700">
                  Web Address
                </label>
                <div class="p-4"></div>
                <div class="mt-1 flex items-center">
                  <div class="mt-1 rounded-md shadow-sm flex w-full">
                  <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm break-none">
                     https://:
                  </span>
                  <input v-model="url" type="text" name="logoUrl" id="LogoUrl" autocomplete="organization" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
                  <!-- <button type="button" class="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Change
                  </button> -->
                </div>
                
              </div>
              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label for="country" class="block text-sm font-medium text-gray-700">Country / Region</label>
                <select v-model="country" id="country" name="country" autocomplete="country" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                </select>
              </div>
              <div class="col-span-6 sm:col-span-3 lg:col-span-1">
                <label for="state" class="block text-sm font-medium text-gray-700">State / Province</label>
                <input v-model="stateProvince" type="text" name="state" id="state" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button @click="createFactory" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon } from '@heroicons/vue/outline'
import { reactive, toRefs } from 'vue'
const navigation = [
  { name: 'Account', href: '#', icon: UserCircleIcon, current: true },
  { name: 'Password', href: '#', icon: KeyIcon, current: false },
  { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: false },
  { name: 'Team', href: '#', icon: UserGroupIcon, current: false },
  { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
]

export default {
    
  setup() {
    let organization = "Florida Bar Association";
    let  description = "Credentialing Body for Florida Lawyers";
    let url = "https://www.floridabar.org/";
    let logoUrl = "https://www-media.floridabar.org/uploads/2017/05/logo-seal-1-1.png";
    let stateProvince = "Florida"; 
    let country = "United States";
    
    const state = reactive({
        organization, 
        description, 
        url, 
        logoUrl, 
        stateProvince, 
        country
    })

    return {
        ...toRefs(state),
        navigation,
    }
  },
  methods:{
      async createFactory(){
          let details = {
              organization: this.organization,
              description: this.description,
              logoUrl:  this.logoUrl,
              url: this.url,
              state: this.stateProvince,
              country: this.country
          }
          if(this.validate(details)){
              await this.$store.dispatch("createCredentialFactory", details)
          }
      },
      validate(details){
          if(!details) return false;
          if(!details.organization || details.organization.length < 3){return false;}
          if(!details.description || details.description.length < 30){return false;}
          if(!details.logoUrl || details.logoUrl.length < 20){return false;}
          if(!details.url || details.url.length < 20){return false;}
          if(!details.state || details.state  === ""){return false;}
          if(!details.country || details.country === ""){return false;}
          return true;
      }
  }
}
</script>


<style lang="scss" scoped>

</style>