<template>
 <canvas id="qr1" class="mx-auto pr-2">

</canvas>
</template>

<script>
import { reactive, toRefs } from 'vue'
//import {useStore} from "vuex";
//border-24 border-dashed
import QRious from "qrious";
import QRHelper from "./../../../../helpers/QRHelper.js";
export default {
    async setup () {
        
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    mounted(){
        let toEmbed = QRHelper.createPatientQRData( this.$store.state.patientAddress, 
                                                    this.$store.state.patientFirstName, 
                                                    this.$store.state.patientMiddleInitial, 
                                                    this.$store.state.patientLastName, 
                                                    this.$store.state.patientBirthday)
        new QRious({
            element: document.getElementById("qr1"),
            value: toEmbed,
            size: 175
         });
    }
}
</script>

<style lang="scss" scoped>

</style>