<template>
     <div>
        <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
            />
        
        <button @click="submit">
            <span v-if="loading"> <i class='fa fa-spinner animate-spin'></i></span>
            <span v-else>Pay Now</span>
        </button>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
    components:{
        StripeCheckout,
    },
     async setup () {
        const lineItems =  [
            {
            price: 'price_1JL7nWA5eISFnysutNevszAn', // The id of the one-time price you created in your Stripe dashboard
            quantity: 1,
            },
        ]
      
        const state = reactive({
            loading: false,
           publishableKey: "pk_test_51ITASLA5eISFnysulKCtJ3cB1hGeqzNUdbXnhjkINb2hd9Iew6GvG4888dVOCpHOx6sdjipfbk0QW9xzTIxiCoCk00PdFWNOi2",
            successURL: 'http://localhost:8080/apps/lite/payment-success',
            cancelURL: 'http://localhost:8080/payment-fail',
        })
    
        return {
            lineItems,
            ...toRefs(state),
        }
    },
    methods:{
        submit () {
        this.loading = true;
        // You will be redirected to Stripe's secure checkout page
        this.$refs.checkoutRef.redirectToCheckout();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>