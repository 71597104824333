<template>
  <nav aria-label="Progress" class='text-left'>
    <ol class="">
      <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
        <template v-if="step.status === 'complete'">
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true" />
          <div class="relative flex items-start group">
            <span class="h-9 flex items-center">
              <span class="relative z-9 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col">
              <span class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</span>
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </div>
        </template>
        <template v-else-if="step.status === 'current'" condition="step.status === 'current'">
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
          <div  class="relative flex items-start group" aria-current="step">
            <span class="h-9 flex items-center" aria-hidden="true">
              <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 border-2 border-indigo-600 rounded-full">
                <i class="text-white fas fa-question"></i>
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col">
              <span class="text-xs pt-1 font-semibold tracking-wide uppercase">{{ step.name }}</span>
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </div>
        </template>
        <template v-else-if="step.status === 'button'" condition="step.status === 'button'">
            <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
            <div  class="relative flex items-start group" aria-current="step">
                <span class="h-9 flex items-center" aria-hidden="true">
                    <img v-if="canSend" @click="send" class="-ml-10 w-40 pt-24 " :class="buttonClasses" src="./../../../assets/images/send-button.png" />
                    <img v-if="!canSend" @click="send" class="-ml-10 w-40 pt-24 " :class="buttonClasses" src="./../../../assets/images/send-button-disabled.png" />
                </span>
                <span class="ml-4 min-w-16 flex flex-col">
                <span class="text-xs pt-1 font-semibold tracking-wide uppercase">{{ step.name }}</span>
                <span class="text-sm text-gray-500">{{ step.description }}</span>
                </span>
            </div>
        </template>  
        <template v-else>
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
          <div  class="relative flex items-start group" aria-current="step">
            <span class="h-9 flex items-center" aria-hidden="true">
              <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-red-600 border-2 border-red-600 rounded-full">
                <i class="text-white fas fa-question"></i>
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col">
              <span class="text-xs pt-1 font-semibold tracking-wide uppercase">{{ step.name }}</span>
              <span class="text-sm text-gray-500 text-left montserrat">{{ step.description }}</span>
              <span v-for="err in step.errors" :key="err"  class="text-sm text-red-500 text-left montserrat">{{err}} </span>
            </span>
          </div>
          
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import {ref} from "vue"
import { CheckIcon } from '@heroicons/vue/solid';

export default {
  components: {
    CheckIcon
  },

  setup(props) {
     let showButton = ref(props.canSend);
    return {
        showButton
    }
  },
  methods:{
      send(){
          if(this.canSend)
            this.sendFunction();
      },
  },
  computed:{
      buttonClasses(){
          if(!this.canSend){
              return "opacity-50";
          } else{
              return "animate-pulse";
          }
      },
      buttonStyle(){
          if(!this.canSend){return "z-index:-1"}
          return "";
      }
  },
  props:["sendFunction", "steps", "canSend"]
}
</script>