<template>
    <login-split-screen v-if="!isLoggedIn">
        <template v-slot:leftPanel>
            <div v-if="showLogin" class="mx-auto w-full max-w-sm lg:w-96">
                <div class=''>
                    <div class="flex-col items-center justify-center">
                        <img class="h-24 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
                        <h2 class="mt-2 text-3xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-600 to-indigo-700">
                            {{Language.SIGN_IN_TEXT}}
                        </h2>
                    </div>
                    
                </div>
                <div class="mt-6">
                    <LoginForm :loginType="ApplicationConstants.LOGIN_TYPE_PROVIDER" :showRegister="showRegister" />
                </div>
            </div>
            <div v-if="!showLogin" class="mx-auto w-full max-w-sm lg:w-96">
                <div class="">
                    <img class="h-20 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
                    <h2 class="mt-2 text-3xl font-medium  text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-600 to-indigo-700">
                        {{Language.CARE_PROVIDER_REGISTRATION_TITLE}}
                    </h2>
                </div>
                <div class="h-auto overflow-y-scroll">
                    <RegistrationForm :showLoginClick="hideRegister" />
                </div>
                <div class='flex pt-4'> 
                 
                </div>
            </div>
        </template>
        <template v-slot:rightPanel>
            <div class="bg-indigo-600">
            <img :class="imageClasses" :src="backgroundImage" alt="" />
            </div>
        </template>
    </login-split-screen>
    <div >
          <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Are You Sure?
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                <a href="/apps/provider/keys" class="font-semibold text-red-600 hover:text-indigo-500">
                make sure to save your VXPassphrase
                </a>
            </p>
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div class="space-y-6" action="#" >
                
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                    <input v-model="checked" id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                    <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                        I have secured my VXPassphrase
                    </label>
                    </div>

                    <div class="text-sm">
                    <a href="/apps/provider/keys" class="font-medium text-indigo-600 hover:text-indigo-500">
                        <i class='fa fa-eye'></i> Keys
                    </a>
                    </div>
                </div>

                <div class="pt-12">
                    <button @click="signoutClicked" type="submit" :disabled="checked ? false : true" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Sign Out
                    </button>
                </div>
                </div>

                <!-- <div class="mt-6">
                <div class="relative">
                    <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500">
                        Share us on:
                    </span>
                    </div>
                </div>

                <div class="mt-6 grid grid-cols-3 gap-3">
                    <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Sign in with Facebook</span>
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                        </svg>
                    </a>
                    </div>

                    <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Sign in with Twitter</span>
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                    </a>
                    </div>

                    <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Sign in with GitHub</span>
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                        </svg>
                    </a>
                    </div>
                </div>
                </div> -->
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import LoginSplitScreen from "./../../../components/tailwind/login/LoginSplitScreen.vue";
import {useStore} from "vuex";
import LoginForm from "../../../components/apps/common/LoginForm.vue"
import LoginImage from "./../../../assets/images/scope-and-mask-purple.png" 
import RegisterImage from "./../../../assets/images/doc-heart-hands-purple.png"
import ApplicationConstants from "../../../constants/ApplicationConstants.js";

import Language from "./../../../constants/language/english.js";
import RegistrationForm from '../../../components/apps/provider/RegistrationForm.vue';
import useBreakpoints from "./../../../composition/useBreakpoints.js";
export default {
    components:{LoginSplitScreen, LoginForm, RegistrationForm},
    setup () {
        let store = useStore();
        let providerSeed = ref(store.state.providerSeed);
        const state = reactive({
            showLogin: true,
            fading:false,
            checked:false,
        })
        let {width, height} = useBreakpoints();
        return {
            Language,
            width, 
            height,
            ApplicationConstants,
            providerSeed,
            ...toRefs(state),
        }
    },
    //TODO: Turn this into a helper 
    methods:{
        showRegister(){
            this.showLogin = false;
        },
        hideRegister(){
            this.showLogin = true;
        },
        signoutClicked(){
            let response = confirm("are you sure?");
            if(response === true){
                this.$store.dispatch("clearPracticeDetails")
                this.$store.dispatch("clearProviderAccount")
            }
        }
    },
    computed:{
       backgroundImage(){
           if(this.showLogin) return LoginImage;
           return RegisterImage;
       },
       imageClasses(){
           console.log("Height:", this.height, " Width:", this.width);
           if(this.fading){
               return "absolute inset-0 h-full w-full object-cover opacity-0"
           }
           return "absolute inset-0 h-full w-full object-cover"
       },
       isLoggedIn(){
           console.log("creds:", this.$store.state.credentials)
           console.log("creds length:", this.$store.state.credentials ? this.$store.state.credentials.length : 0 )
           if(this.$store.state.credentials === undefined) {return false}
           else if(this.$store.state.credentials.length === 0) {return false}
           return true;
       },
    }
}
</script>

<style lang="scss" scoped>

</style>