import { computed, onMounted, onUnmounted, ref } from "vue"

export default function () {
  let windowWidth = ref(window.innerWidth)
  let windowHeight = ref(window.innerHeight)

  const onWidthChange = () => windowWidth.value = window.innerWidth
  const onHeightChange = () => windowHeight.value = window.innerHeight
  onMounted(() => {window.addEventListener('resize', onWidthChange); window.addEventListener('resize', onHeightChange);})
  onUnmounted(() => {window.removeEventListener('resize', onWidthChange); window.addEventListener('resize', onHeightChange);})
  
  const type = computed(() => {
    if (windowWidth.value < 550) return 'xs'
    if (windowWidth.value > 549 && windowWidth.value < 719) return 'sm'
    if (windowWidth.value > 720 && windowWidth.value < 1080) return 'md'
    if (windowWidth.value > 1079  && windowWidth.value < 1200) return 'lg'
    return "xl"
  })

  const width = computed(() => windowWidth.value)
  const height = computed(() => windowHeight.value)

  return { width, type, height }
}