<template>
    <div class="about bg-black text-white text-7xl p-2 m-2">
        <div class='text-blue-500' > 
            <div class='flex text-lg'>
                <a class='px-4 py-2' href="https://fontawesome.com/v5.15/icons?d=gallery&p=2" >Font Awesome </a>
                <a class='px-4 py-2' href="https://heroicons.com/" >Hero Icons </a>
                <a class='px-4 py-2' href="https://tailwindcss.com/" >Tailwind CSS </a>
                <a class='px-4 py-2' href="https://tailwindui.com/" >Talwind UI </a>
            </div>
        </div>
        <div class='flex space-x-6'>
            <div class='flex-col'>
                <div> <label class='text-lg'> Hospital - User </label></div>
                <div> <i class="fas fa-hospital-user"></i> </div>
            </div>
            <div class='flex-col'>
                <div> <label class='text-lg'> Hospital - User </label></div>
                <div> <i class="fas fa-hospital-user"></i> </div>
            </div>
        </div>
        <div class="flex">
            <div class="m-4 p-4"> 
            <label class='text-lg text-white'> Paste icon code here: </label>
            <input type="text" v-model="iconClass" class="w-full text-black" />
            </div>
               <div class="m-4 p-4"> 
            <label class='text-lg text-white'> Paste icon color here: </label>
            <input type="text" v-model="iconColor" class="w-full text-black" />
            </div>
             <div class="m-4 p-4"> 
            <label class='text-lg text-white'> Paste background color here: </label>
            <input type="text" v-model="backgroundColor" class="w-full text-black" />
            </div>
      </div>
      <div :class="`${backgroundColorStyle}`"> <i :class="iconStyle"></i> </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            iconClass: "",
            iconColor: "",
            backgroundColor: "black"
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
        iconStyle(){
            let response = "p-4 m-4 ";
            if(this.iconClass !== "") response = response + this.iconClass + " ";
            if(this.iconColor !== "") response = response + "text-" + this.iconColor + " ";
            if(this.backgroundColor !== "") response = response + "bg-" + this.backgroundColor;
            return response;
        },
        backgroundColorStyle(){
            return "bg-" + this.backgroundColor;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>