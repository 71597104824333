<template>
<div> 
<div v-if="isAdmin"> 
    <menu-dark-base/>
  <Suspense> 
    <template #default >
      <router-view/>
    </template>
    <template #fallback >
      <div class=""> Loading ... </div>
    </template>
  </Suspense>
  </div>
  <div v-else-if="isPatient"> 
    <Suspense> 
      <template #default >
        <router-view />
      </template>
      <template #fallback >
        <div class=""> Loading ... </div>
      </template>
    </Suspense>
  </div>
  <div v-else-if="isProvider"> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <div class=""> Loading ... </div>
      </template>
    </Suspense>
  </div>
   <div v-else-if="isLite"> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <div> 
        <loading-panel></loading-panel>
        <Footer></Footer>
        </div>
      </template>
    </Suspense>
  </div>
  <div v-else>  <!-- No Known Type --> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <loading-panel></loading-panel>

      </template>
    </Suspense>
  </div>
  </div>
</template>

<script> 
//import {reactive, toRefs} from "vue";
import MenuDarkBase from "./components/tailwind/menus/MenuDarkBase.vue"
import LoadingPanel from "./components/apps/lite/LoadingPanel.vue"
import ApplicationConstants from "./constants/ApplicationConstants.js";
import Footer from './components/apps/lite/Footer.vue';
export default {
  name: "App",
  computed:{
    isAdmin(){
      
      let _is = this.$route.path.includes('admin');
      return _is;
    },
    isProvider(){
      let _is = this.$route.path.includes('provider');
      return _is
    },
    isPatient(){
      let _is = this.$route.path.includes('patient');
      return _is;
    },
    isLite(){
      let _is = this.$route.path.includes('lite');
      return _is;
    },
    network(){
      return ApplicationConstants.TEST_NETWORK;
    }
    
  }, 
  components:{
    MenuDarkBase,
    Footer,
    LoadingPanel,
  },
}
</script> 

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: 'Montserrat', sans-serif;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
