<template>
    <div>
        <lite-menu v-on:buy-clicked="submit"></lite-menu>
        <div class="flex items-center justify-center">
            <div class="text-4xl font-black text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-600">About VXPASS</div>
        </div>
        <Footer></Footer>
        <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
            />
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import LiteMenu from "./../../../components/apps/lite/LiteMenu.vue"
import Footer from '../../../components/apps/lite/Footer.vue';

export default {
     components:{
        StripeCheckout,
        LiteMenu,
        Footer,
    },
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
     methods:{
         async submit () {
            this.$refs.checkoutRef.redirectToCheckout();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>