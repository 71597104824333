<template>
    <div>
       <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125.15 125.15"><defs><linearGradient id="linear-gradient" x1="456.13" y1="377.9" x2="477.66" y2="377.9" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#f24cbf"/><stop offset="1" stop-color="red"/></linearGradient><linearGradient id="linear-gradient-2" x1="539.35" y1="378.26" x2="557" y2="378.26" xlink:href="#linear-gradient"/><linearGradient id="linear-gradient-3" x1="26.64" y1="62.58" x2="99.53" y2="62.58" xlink:href="#linear-gradient"/></defs><g id="Oval-2"><circle cx="62.58" cy="62.58" r="62.58" style="fill:#fff"/></g><path d="M460.63,358l6.49,34.68L473.56,358h4.1l-7.46,39.86h-6.55L456.13,358Z" transform="translate(-443.23 -315.81)" style="fill:url(#linear-gradient)"/><path d="M548.06,358.33c6.26,0,8.94,3.7,8.94,9.74v4.78c0,6.32-3,9.62-9.28,9.62h-3.93v15.72h-4.44V358.33Zm-.34,20.16c3.3,0,4.89-1.54,4.89-5.35v-5.3c0-3.64-1.3-5.47-4.55-5.47h-4.27v16.12Z" transform="translate(-443.23 -315.81)" style="fill:url(#linear-gradient-2)"/><line x1="27.7" y1="27.19" x2="98.47" y2="97.96" style="fill:#fff;stroke-miterlimit:10;stroke-width:3px;stroke:url(#linear-gradient-3)"/><line x1="98.47" y1="27.19" x2="27.7" y2="97.96" style="fill:#fff;stroke-miterlimit:10;stroke-width:3px;stroke:url(#linear-gradient-3)"/></svg>

    </div>
</template>

<script setup>

</script>
