<template>
    <div class='grid grid-cols-3'>
        <div class='col-span-1 flex items-center mx-auto'>
            <div><i :class="iconClasses"></i></div>
        </div>
        <div class='col-span-2'>
            <div> <i class="far fa-hospital"></i> <span class='text-warm-gray-600'> {{token.mfg}} </span> </div>
            <div> <i class="fas fa-box-open"></i>  <span class='text-warm-gray-600'>{{token.lot}} </span></div>
            <div> <i class="far fa-calendar-alt"></i> <span class='text-warm-gray-600'>{{token.distributed}}</span></div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    methods:{},
    computed:{
        iconClasses(){
            let response = "fa fa-syringe text-2xl";
            if(this.token.distributed){response += " text-indigo-600"}
            else {response += " text-gray-400"}
            return response;
        }
    },
    props: ["token"]
}
</script>

<style lang="scss" scoped>

</style>