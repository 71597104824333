<template>
    <LoggedOut />
</template>

<script>
import { reactive, toRefs } from 'vue'
import LoggedOut from "./layouts/LoggedOut.vue"

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    components:{LoggedOut}
}
</script>

<style lang="scss" scoped>

</style>