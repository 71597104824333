<template>
    <div>
            <div class='selector shadow'>
                <Listbox as="div" v-model="selectedManufacturer" >
                    <div class="mt-1 relative">
                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                        <span class="block truncate">{{ selectedManufacturer }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>
                    <label for="manufacturer" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{Language.MANUFACTURER}}</label>
                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer }">
                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <span :class="[selectedManufacturer ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ mfg }}
                            </span>

                            <span v-if="selectedManufacturer" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute z-8 inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
        </div>
        <div class='grid grid-cols-1 md:grid-cols-2 rounded'>
            <div class="mt-4  relative col-span-1 border border-gray-300 rounded-md px-3 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 shadow">
                <label for="lotID" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{Language.LOT_ID}}</label>
                <input v-model="lotID" @change="lotIDChanged" type="text" name="lotID" id="lotID" class="block w-full rounded  p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="LOT ID" />
            </div>
             <div class="mt-4 relative col-span-1  border border-gray-300 rounded-md px-3 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 shadow">
                <label :for="`distributedOn${doseIndex}`" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{Language.DISTRIBUTED_DATE}}</label>
                <input v-model="distributedOn" @change="distributedOnChanged" type="date" :name="`distributedOn${doseIndex}`" :id="`distributedOn${doseIndex}`" class="block w-full rounded p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs, watchEffect } from 'vue'
import { 
    Listbox, 
    ListboxButton, 
    //ListboxLabel, 
    ListboxOption, 
    ListboxOptions } from '@headlessui/vue'
import {useStore} from "vuex";
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import VXVaccineConstants from "./../../../../constants/VXVaccineConstants.js";
import Language from "./../../../../constants/language/english.js";
const mfgs = Object.entries(VXVaccineConstants.VACCINE_MANUFACTURERS).map((i) => i[1]);
console.log(mfgs);
export default {
    setup (props) {
        const store = useStore();
        let mfg = "Manufacturer";
        let _lot = "Lot ID";
        let _distributed = new Date()
        if(props.doseIndex === 1) {
            mfg = store.state.dose1Manufacturer;
            _lot = store.state.dose1LotID;
            _distributed = store.state.dose1DistributedDate;
        } else if(props.doseIndex === 2){
            mfg = store.state.dose2Manufacturer;
            _lot = store.state.dose2LotID;
            _distributed = store.state.dose2DistributedDate;
        }
        const selectedManufacturer = ref(mfg)
        const lot = ref(_lot);
        const distributed = ref(_distributed);

        
         const state = reactive({
            lotID: lot.value,
            distributedOn: distributed
        })
        watchEffect(() => {
            if(props.doseIndex === 1 ){
                if(selectedManufacturer.value !== "" && selectedManufacturer.value !== "Manufacturer"){
                    store.commit( "setDose1Manufacturer", selectedManufacturer.value);  
                }
            } else if(props.doseIndex === 2){
                if(selectedManufacturer.value !== "" && selectedManufacturer.value !== "Manufacturer"){
                    store.commit( "setDose2Manufacturer", selectedManufacturer.value);  
                }
            }
        })
        return {
            VXVaccineConstants,
            Language,
            selectedManufacturer,
            ...toRefs(state)
            }
    },
    components: {
        Listbox,
        ListboxButton,
        //ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
    },
    computed:{
        manufacturers(){
            return mfgs
        }
    },
    methods:{
        lotIDChanged(){
            if(this.doseIndex ===  1 ) {
                this.$store.commit("setDose1LotID", this.lotID);
            } else {
                console.log(this.lotID);
                this.$store.commit("setDose2LotID", this.lotID);
            }
        },
        distributedOnChanged(){
             if(this.doseIndex === 1 ) {
                this.$store.commit("setDose1DistributedDate", this.distributedOn);
            } else {
                console.log(this.distributedOn);
                this.$store.commit("setDose2DistributedDate", this.distributedOn);
            }
        }
    },
    props:["doseIndex"]
}
</script>

<style  scoped>

</style>