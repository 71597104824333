<template>
    <div>
        <LoggedInWide >
            <template v-slot:welcomePanel >
                <div class="rounded-xl bg-white shadow">
                    <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                    <div class="bg-white p-2">
                        <div class="grid grid-cols-4 items-center justify-items-center">
                            <div class="flex-shrink-0 h-full flex-grow col-span-1 grid items-center items-justify-center">
                                <img class="mx-auto h-24 w-24  rounded-full" src="./../../../assets/images/VXPASS-Provider-Circle-Logo.png" alt="" />
                            </div>
                            <div class="mt-4 col-span-2 text-center flex-grow">
                              <VXQRScanner  :respond="respondToScan"/>
                            </div>
                            <div class="mt-5 col-span-1 flex sm:mt-0 text-left ">
                                <p class='text-2xl font-normal break-normal'>
                                    {{Language.SCAN_QR_CODE}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 ">
                        <div class="px-6 py-1 text-sm font-medium text-center">
                           <div class="grid grid-cols-2 space-x-1">
                               <div class="col-span-1">
                                   <StatusMini doseType="first" :status="firstDoseStatus" /> 
                                   <FormMini :doseIndex="1"/>
                               </div>
                               <div class="col-span-1">
                                   <StatusMini doseType="second" :status="secondDoseStatus" /> 
                                   <FormMini :doseIndex="2" />
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actionsPanel >
                <div class="grid grid-cols-1 sm:grid-cols-2 bg-white rounded shadow">
                    <div class='col-span-1 '>
                        <PatientDetailsAndForm :edit="editPatientDetails" :savePatientInfo="savePatientInfo" :close="hidePatientEdit" :showEditFunction="showPatientEdit"/>
                    </div>
                    <div class='col-span-1 p-3 m-3 ml-2 pb-10 mb-10'>
                        <div class="m-2 p-2">
                            <VerticalTimeline :steps="steps" :canSend="showSendButton" :sendFunction="sendButtonClick"/>
                        </div>
                    </div>
                    <button @click="clearPatient"> Clear </button>
                </div>
            </template>
            <template v-slot:footer>
                <two-button-wide-modal :open="showModal" v-on:modal-confirmed="confirmClicked" v-on:modal-canceled="cancelClicked" title="" message="The details of this confirmation can not be changed. Please double check that all the information is complete." confirmText="Send"> 
                    <template v-slot:content >
                        <div class='pt-6 flex space-x-3 items-center'>
                            <div class='flex-grow shadow-xl' >
                                <dose-details-mini statusClasses="fa fa-syringe text-3xl text-indigo-600" :titleText="dose1Manufacturer" :details="dose1DistributedDate"></dose-details-mini>
                            </div>
                            <div class='flex-grow shadow-xl' >
                                <dose-details-mini statusClasses="fa fa-syringe text-3xl text-indigo-600" :titleText="dose2Manufacturer" :details="dose2DistributedDate"></dose-details-mini>
                            </div>
                        </div>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-1'>
                                <div class="pt-6 montserrat items-left text-left">
                                     <label class='text-left text-sm text-warm-gray-700'> {{Language.PATIENT_NAME_TITLE}} </label>
                                     <div class='flex font-medium text-lg'>
                                        <div class="m-1">
                                            <div class=''>{{patientFirstName}}</div>
                                        </div>
                                        <div class="p-1">
                                            {{patientMiddleInitial}}
                                        </div>
                                        <div class="m-1">
                                            {{patientLastName}}
                                        </div>
                                     </div>
                                </div>
                                <div class='pt-6 items-left text-left'> 
                                     <label class='text-left text-sm text-warm-gray-700'> Birthday </label>
                                     <div class='flex font-medium text-lg'>
                                         <div class="m-1">
                                            <div class='text-warm-gray-900'>{{patientBirthday}} </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class='col-span-1'>
                                <div class="flex-col">
                                    <div class='pt-6 items-left text-left'> 
                                        <label class='text-left text-sm text-warm-gray-700'> {{Language.STATE_ID}} </label>
                                        <div class='flex font-medium text-lg'>
                                            <div class="m-1">
                                                <div class='text-warm-gray-900'>{{patientStateID}} </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div class='pt-6 items-left text-left'> 
                                        <label class='text-left text-sm text-warm-gray-700'> {{Language.PASSPORT_NUMBER}} </label>
                                        <div class='flex font-medium text-lg'>
                                            <div class="m-1">
                                                <div class='text-warm-gray-900'>{{patientPassportNumber}} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </two-button-wide-modal>
            </template>
        </LoggedInWide>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore, mapState} from 'vuex';
import LoggedInWide from "./layouts/LoggedInWide.vue" 
import Language from "./../../../constants/language/english.js"
import VXQRScanner from './../../../components/apps/common/VXQRScanner.vue'
import StatusMini from "./../../../components/apps/common/dose/StatusMini.vue"
import FormMini from "./../../../components/apps/common/dose/FormMini.vue"
import PatientDetailsAndForm from "./../../../components/apps/common/PatientDetailsAndForm.vue"
import VerticalTimeline from "./../../../components/apps/provider/VerticalTimeline.vue"
import Run from "run-sdk"
import AccountSampleKeys from "./../../../constants/AccountSampleKeys.js";
import VXVaccineConstants from "./../../../constants/VXVaccineConstants.js";
import Utilities from './../../../helpers/Utilities.js';
import VXConstants from "./../../../constants/VXConstants.js";
import VXTransactionHelper from "./../../../helpers/VXTransactionHelper.js";
import TwoButtonWideModal from "./../../../components/tailwind/modals/TwoButtonWide.vue"
import DoseDetailsMini from '../../../components/apps/provider/DoseDetailsMini.vue';

export default {
    components: {LoggedInWide, VXQRScanner, StatusMini, FormMini, PatientDetailsAndForm, VerticalTimeline, TwoButtonWideModal, DoseDetailsMini},
    setup () {
        const store = useStore();
        console.log("Provider Private Key", store.state.providerPrivKey);
        const state = reactive({
            count: 0,
            emptyFields: [],
            editPatientDetails: false,
            showSendButton: true,
            displayUpdate: false,
            showModal: false,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods:{
        savePatientInfo(details){
            this.$store.dispatch("updatePatientDetails", details);
            this.editPatientDetails = false;
            if(this.checkPatientInfoComplete()){
                this.showSendButton = true;
            }
        },
        checkPatientInfoComplete(){
            let complete = true; 
            let response = [];
            console.log(this.$store.state.patientAddress);
            if(this.$store.state.patientAddress === ""){complete = false; response.push("Address")}
            if(this.$store.state.patientFirstName === ""){complete = false; response.push("First Name")}
            if(this.$store.state.patientMiddleInitial === ""){complete = false; response.push("Middle Initial")}
            if(this.$store.state.patientLastName === ""){complete = false; response.push("Last Name")}
            if(!this.$store.state.patientBirthday){complete = false; response.push("Birthday")}
            // if(this.$store.state.patientStateID === ""){complete = false; response.push("State ID")}
            // if(this.$store.state.patientPassportNumber === ""){complete = false; response.push("Passport Number")}
            this.emptyFields = response;
            console.log(this.emptyFields)
            if(response.length > 0){this.showSendButton = false; return response}
            this.showSendButton  = true;
            return complete;
        },

        showPatientEdit(){
            this.editPatientDetails = true;
            this.showSendButton = false;
        },
        hidePatientEdit(){
            this.editPatientDetails = false;
            this.checkPatientInfoComplete();
        },
        async respondToScan(data){
            console.log("Scanned from Control Writing from Page: ", data)
            let input = {};
            let _input = JSON.parse(data);
            input.firstName = _input['firstName'];
            input.middleInitial = _input['middleInitial'];
            input.lastName = _input['lastName'];
            input.birthday = _input['birthday'];
            input.address = _input['address'];
            console.log(input);
            console.log(await this.$store.dispatch("updatePatientDetails", _input)) 
            this.showPatientEdit = !this.showPatientEdit;
            location.reload(); 
        },
        sendButtonClick(){
        console.log("send button click")
           this.showModal = true;
        },
        async confirmClicked(){
            console.log("confirm clicked");
             try{
                let f = true;
                if(f){
                    let _run = new Run({network:"test", trust: "*", purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, owner: this.$store.state.providerPrivKey});
                    let _ndc = VXVaccineConstants.VACCINE_NDC_CODES_1[this.$store.state.dose1Manufacturer];
                    console.log("NDC:", _ndc);
                    let options1 = {manufacturer: this.$store.state.dose1Manufacturer, ndc: _ndc, lot: this.$store.state.dose1LotID, expiry: Utilities.dateString(+365), distributed: this.$store.state.dose1DistributedDate, site: "", firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
                    let selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                    let _class = await _run.load(VXConstants.VACCINATION_RECORD_CLASS_LOCATION);
                    await _class.sync()
                    let _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, this.$store.state.patientAddress, options1);
                    let options2 = {manufacturer: this.$store.state.dose2Manufacturer, ndc: _ndc, lot: this.$store.state.dose2LotID, expiry: Utilities.dateString(+365), distributed: this.$store.state.dose2DistributedDate, site:"", firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
                    _tx =  VXTransactionHelper.addRecordToTransaction(_run, _tx, selectedCredential, _class, this.$store.state.patientAddress, options2);
                    let result =  await _tx.publish();
                    if(result){console.log(result);}
                    alert(Language.SAVE_RECORD_SUCCESS_MESSAGE);
                    this.$store.dispatch("clearPatientPersonalData");
                }
                this.showModal = false;
            }catch(err){
                console.log(err); alert(err)
            }
        },
        cancelClicked(){
            console.log("cancel clicked");
            this.showModal = false;
        },
        clearPatient(){
            this.$store.dispatch("clearPatientPersonalData");
        }
    },
    computed:{
        firstDoseStatus(){
            return "staged"
        },
        secondDoseStatus(){
            return "staged"
        },
        steps(){
            return [
                            { name: 'Dose Info', description: 'All Information about patient doses must be complete.', href: '#', status: 'complete' },
                            {
                                name: 'Profile information',
                                description: `Provable Patient Infomation is ${this.patientInfoComplete}`,
                                errors: this.emptyFields,
                                href: '#',
                                status: 'upcoming',
                            },
                            { name: `
                            



                            
                            `, description: '', href: '#', status: 'button' },
                            ]
        },
        patientInfoComplete(){
            let response = this.checkPatientInfoComplete()
            if(response === true)
                return "complete"
            else{
                return `incomplete.
                `; 
            }    
        },
        formattedEmptyFields(){
            if(this.emptyFields.length === 0 ) return; 
            let response = this.emptyFields.forEach((i) => 
            `${i}
`);
            return response;
        },
        ...mapState(['dose1Manufacturer', 'dose2Manufacturer', 'dose1DistributedDate', 'dose2DistributedDate', 'patientFirstName', 'patientMiddleInitial', 'patientLastName', 'patientBirthday', 'patientStateID', 'patientPassportNumber'])
    }
}
</script>

<style scoped>

</style>