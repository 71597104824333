<template>
<div class="center ">
    <div class='overflow-hidden'> 
        <div class='p-1 m-1 stream rounded-xl bg-gradient-to-r from-pink-400 via-pink-600 to-pink-700'>
            <qr-stream @decode="onDecode" class="mb rounded-xl overflow-hidden">
                <div style="color: red;" class="frame"></div>
            </qr-stream>
        </div>
    
    <qr-capture v-if="showUpload" @decode="onDecode" class="pt-2 mt-2 shadow"></qr-capture>
    </div>
    <div v-if="displayResult" class="result text-white">
        <div > Scanned: </div>
        <div> 
        </div>
    </div>
</div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup (props) {
        const onDecode = async(data) => {
            console.log(data.toString());
            if(data.length > 0){
                state.data = JSON.parse(data);
                if(state.data["firstName"]){state.firstName = state.data["firstName"]}
                if(state.data["middleInitial"]){state.middleInitial = state.data["middleInitial"]}
                if(state.data["lastName"]){state.lastName = state.data["lastName"]}
                if(state.data["address"]){state.patientAddress = state.data["address"]}
                console.log(state.data);
                if(props.respond)
                    props.respond(data);
            }
        }
        const state = reactive({
            showQR: false,
            data: null,
            firstName: "",
            middleInitial: "",
            displayResult: false,
            showUpload: false,
        })
    
        return {
            onDecode,
            ...toRefs(state),
        }
    },
    props: ['respond']
}
</script>

<style scoped>
.stream {
  max-height: 150px;
  max-width: 150px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 175px;
  width: 175px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
</style>