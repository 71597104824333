<template>
    <div class='text-black text-sm'>
        {{patientAddress}}
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {mapState} from "vuex"

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
        ...mapState(["patientAddress"])
    }
}
</script>

<style lang="scss" scoped>

</style>