<template>


<div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
      </svg>

      <div class="relative">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          A better  Digital Vaccination Record
        </h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
         VXPASS™ &amp; VXLITE™ are better than paper cards in the following ways:
        </p>
      </div>

      <div class="text-left relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Patient Owned Records
            <a class='text-sm text-right text-indigo-500' norel noopener target="_blank" href="https://run.network/explorer/?query=d0faca49ca6c20868d3f6438ad2bb16b0a759287957d05ad2abd7befc23d6022_o1&network=test">
                    <i class="fas fa-external-link-alt"></i> see an example
                </a>
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            VXPASS™ &amp; VXLITE™ do not require any personal information to create an account. Because you own your records, there is <b>no</b> way to look you up by name, social security number, or other sensitive personal information.
           
          </p>

          <dl class="mt-10 space-y-10">
            <div v-for="item in transferFeatures" :key="item.id" class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ item.name }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">
                {{ item.description }} 
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
            <defs>
              <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
          </svg>
          <img class="relative mx-auto" width="490" src="https://tailwindui.com/img/features/feature-example-1.png" alt="" />
        </div>
      </div>

      <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Verification By <br/> Anyone, Anytime, Anywhere
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              VXVerify™ can be used without an account, to scan any VXPASS™ or VXLITE™ QR code and verify the validity of the records. 
            </p>

            <dl class="text-left mt-10 space-y-10">
              <div v-for="item in communicationFeatures" :key="item.id" class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ item.name }}</p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  {{ item.description }}
                </dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
              <defs>
                <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
            </svg>
            <img class="relative mx-auto" width="490" src="https://tailwindui.com/img/features/feature-example-2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { BanIcon, LockClosedIcon, LightningBoltIcon, CurrencyDollarIcon, KeyIcon } from '@heroicons/vue/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'No Personal Information Stored',
    description:
      'VXPASS™ & VXLITE™ use digital signatures to prove the validity of your records, rather than storing your sensitive personal information.',
    icon: LockClosedIcon,
  },
  {
    id: 2,
    name: 'Your Records, Your Property',
    description:
      'VXPASS™ & VXLITE™ create help you create records that you own. Neither VXPASS™ nor VXLITE™ own any of the records created through the platform.',
    icon: KeyIcon,
  },
  {
    id: 3,
    name: 'Instant Verfication',
    description:
      'The records that VXPASS™ & VXLITE™ contain all the information needed for anyone to verify their validity directly from the blockchain.',
      icon: LightningBoltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'No Account Required',
    description:
      'VXPASS™ makes it as easy as possible for anyone to verify the records presented by their customers, members, or particiapnts without any complicated sign up or subscription fees.',
    icon: BanIcon,
  },
  {
    id: 2,
    name: 'Free To Verify',
    description:
      'There are no on-going fees, and that includes for verifying records. Have your records verified, or verify your teams records at no additional cost.',
    icon: CurrencyDollarIcon,
  },
]

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
             transferFeatures,
      communicationFeatures,
            ...toRefs(state),
        }
    }
}
</script>

<style lang="scss" scoped>

</style>