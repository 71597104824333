<template>
    <div class="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
          <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
            <div>
              <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">{{safeHeading}}</h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                {{safeTitle}}
              </p>
              <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                {{safeContent}}
              </p>
            </div>
            <div class="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
              <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5" :src="safeImage" alt="" />
            </div>
          </div>
        </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
        safeTitle(){
            if(!this.title) return "No server? No problem.";
            else return this.title;
        },
        safeHeading(){
            if(!this.heading) return "Serverless";
            return this.heading;
        },
        safeContent(){
            if(!this.content) return "Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla."
            return this.content;
        },
        safeImage(){
            if(!this.image) return "https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg";
            return this.image;
        },
    },
    props:['title', 'heading', 'content', 'image']
}
</script>

<style lang="scss" scoped>

</style>