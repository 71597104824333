module.exports = {
    CREDENTIAL: "Credentials",
    SELECTED_CREDENTIAL: "Selected Credentials",
    DOSE: "Dose",
    FIRST_NUMBER:"1st",
    SECOND_NUMBER:"2nd",
    SIGN_IN_TEXT: "Sign In",
    SIGN_OUT_TEXT: "Sign Out",
    SIGN_UP_TEXT: "Create Account",
    CREATE_PATIENT_BUTTON_TEXT: "Create Patient",
    REMEMBER_ME_TEXT: "Remember Me", 
    SEED_PHRASE: "Seed / Password",
    HOME: "Home",
    HISTORY: "History",
    PROFILE: "Profile",
    PROVIDER_PROFILE: "Provider Profile",
    SETTINGS: "Settings",
    ACCOUNT: "Account",
    ALERTS: "Alerts",
    STATE_ID: "State ID",
    PASSPORT_NUMBER: "Passport Number",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    MIDDLE_INITIAL: "Middle Initial",
    BIRTHDAY: "Birthday",
    MANUFACTURER: "Manufacturer",
    LOT_ID: "Lot Number",
    DISTRIBUTED_DATE: "Distributed On",
    IS_VERIFIED: "Verified",
    SITE_NAME: "Site Name",
    HIDE: "Hide",
    SHOW: "Show",
    ID: "ID",
    QR: "QR Code",
    SCAN_QR_CODE: "Scan QR Code",
    SEED: "Seed",
    HIDDEN: "Hidden",
    PRIVATE_KEY: "Private Key",
    PATIENT_ID: "Patient ID",
    PATIENT_DETAILS: "Patient Details",
    PATIENT_NAME_TITLE: "Patient Name",
    SET_PATIENT_ADDRESS: "Set Address",
    NEW_RECORD_TITLE: "Patient Record Information",
    NEW_RECORD_SUBTITLE:"Detailing the patients covid protocol completion. ",
    COPY: "Copy",
    ADDRESS: "Address",
    CHECK_RECORDS: "Check For Records" ,
    MY_ACCOUNT_TITLE: "My Account Details",
    SAVE_VACCINATION_RECORDS: "Save Vaccination Records",
    SAVE_RECORD_SUCCESS_MESSAGE: "The Records were successdfully saved. You may instruct the patient to click the 'Check for Records button' on their application.",
    MY_ACCOUNT_EXPLANATION_1: `VXPASS is built on blockchain to protect your privacy. 
    This means your account is entirely private. We dont have servers that create your account, it was created on the device you first used to log in and never sent to VXPASS.`,
    MY_ACCOUNT_EXPLANATION_2: `For that reason we CAN NOT recover your seed or private key. You can use your Seed as your secure password to VXPASS, but if you would like to take your records to another service you may also need you PrivateKey
    We suggest emailing yourself the first 6 words of your seed, and text messaging yourself the second 6 words. That will mean, with your seed you can always recover your Privake Key by logging into VXPASS.`,
    LOGOUT_EXPLANATION: "Logging out will clear all of your account data. Please save your seed & private key in a safe place before you log out.",
    BACK_TO_SIGN_IN_TEXT: "Back To Sign In",
    NEW_RECORD_INFORMATION_DISCLAIMER: "The patient's personal information is not stored on the blockchain. It is only needed to create the patients digital signature. ",
    CARE_PROVIDER_REGISTRATION_TITLE: "Register",
    PROVIDER_REGISTRATION_NAME_FIELD_TITLE_TEXT: "Your Name",
    PROVIDER_REGISTRATION_BUSINESS_NAME_FIELD_TITLE_TEXT: "The name of your business",
    PROVIDER_REGISTRATION_EMAIL_FIELD_TITLE_TEXT: "Your busniess email address",
    PROVIDER_REGISTRATION_PHONE_FIELD_TITLE_TEXT: "Your busniess phone number",
    PROVIDER_REGISTRATION_MOBILE_PHONE_FIELD_TITLE_TEXT: "Your mobile phone number",
    PROVIDER_REGISTRATION_CREDENTIAL_BY_FIELD_TITLE_TEXT: "Organization which provides your license.",
    PROVIDER_REGISTRATION_NAME_FIELD_PLACEHOLDER_TEXT: "Dr Jane Doe",
    PROVIDER_REGISTRATION_BUSINESS_NAME_FIELD_PLACEHOLDER_TEXT: "Broward Country Family Medical Practice",
    PROVIDER_REGISTRATION_EMAIL_FIELD_PLACEHOLDER_TEXT: "jane@bcgmcorg.com",
    PROVIDER_REGISTRATION_PHONE_FIELD_PLACEHOLDER_TEXT: "888-999-8888",
    PROVIDER_REGISTRATION_MOBILE_PHONE_FIELD_PLACEHOLDER_TEXT: "888-999-8888",
    PROVIDER_REGISTRATION_CREDENTIAL_BY_FIELD_PLACEHOLDER_TEXT: "Florida Medical Association",

}