<template>
    <LoggedIn >
        <template v-slot:welcomePanel >

            <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="bg-white p-6 text-left">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <div class="sm:flex sm:space-x-5">
                            <div class="flex-shrink-0">
                                <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="user.imageUrl" alt="" /> -->
                            </div>
                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p class="text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.PROVIDER_PROFILE}}</p>
                                <p class="text-sm font-medium text-gray-600">{{  }}</p>
                            </div>
                        </div>
                        <div class="mt-5 flex justify-center sm:mt-0">
                        <button @click="router.push('/apps/provider/keys')" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                            View Keys
                        </button>
                        </div>
                    </div>
                    <div class="grid grid-cols-4">
                        <div class='col-span-1'>
                            <img class="h-24 w-24 mx-auto" src="./../../../assets/images/hospital-user.png" />
                        </div>
                        <div class='col-span-3 flex-col'>
                            <p class='text-left'> This information can be public. Make sure to share the correct verifiable information </p>
                            <div class="flex-1 sm:flex w-full">
                                <div class='p-1 m-1 flex-grow'> 
                                    <label for="practiceName" class=" text-sm font-medium text-gray-700">Practice Name</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input v-model="practiceName" 
                                        type="text" name="practiceName" 
                                        id="practiceName" 
                                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                        placeholder="Delray Beach Famil Practice" />
                                    </div>
                                </div>
                                <div class='p-1 m-1 flex-grow'> 
                                    <label for="businessID" class=" text-sm font-medium text-gray-700">Business ID</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <OfficeBuildingIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input v-model="businessId" 
                                        type="text" 
                                        name="businessID" 
                                        id="businessID" 
                                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                        placeholder="E04-89876-89" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class='p-1 m-1 flex-grow'> 
                            <label for="streetAddress" class=" text-sm font-medium text-gray-700">Street Address</label>
                            <div class="mt-1 relative rounded-md shadow-sm ">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LocationMarkerIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input 
                                v-model="streetAddress" 
                                type="text" 
                                name="streetAddress" 
                                id="streetAddress" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="123 main street" />

                            </div>
                        </div>
                        <div class='p-1 m-1 flex-grow'> 
                            <label for="streetAddress2" class=" text-sm font-medium text-gray-700">Address 2</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LocationMarkerIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input 
                                v-model="streetAddress2" 
                                type="text" 
                                name="streetAddress2" 
                                id="streetAddress2" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="suite 4a" />
                            </div>
                        </div>        
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="city" class=" text-sm font-medium text-gray-700">City</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input v-model='city' 
                                type="text" 
                                name="city" 
                                id="city" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="Coral Springs" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="state" class=" text-sm font-medium text-gray-700">State</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input 
                                v-model="stateProvince" 
                                type="text" 
                                name="state" 
                                id="state" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="Florida" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="zipPostalcode" class=" text-sm font-medium text-gray-700">Zip/Postal Code</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input 
                                v-model="zipPostalCode" 
                                type="text" 
                                name="zipPostalcode" 
                                id="zipPostalcode" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="33301" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="country" class=" text-sm font-medium text-gray-700">Country</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input v-model="country" 
                                type="text" 
                                name="country" 
                                id="country" 
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="USA" />
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class="flex-grow"><button @click="save" class="w-full bg-indigo-500 rounded text-white p-2 m-2"> Save</button></div>
                        <div class="flex-grow"><button @click="clear" class="w-full bg-red-500 rounded text-white p-2 m-2"> Clear</button></div>
                    </div>
                </div>
                
            </div>
        </template>
        <template v-slot:actionsPanel>
            <div class="grid grid-cols-6">
                    <div class="col-span-1">
                        <img class='h-16 w-16 sm:h-24 sm:w-24 mx-auto' src="./../../../assets/images/Credential.png" />
                    </div>
                    <div class="col-span-5">
                        <p class=" pt-6 text-left text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.CREDENTIAL}}</p>
                        <p class="text-sm font-medium text-gray-600">{{  }}</p>
                    </div>
            </div>
            <div class='shadow'>
                <div class="p-2 m-2 sm:mx-4 sm:px-4 sm:pt-2 shadow-xl">
                    <CredentialList />
                </div>
            </div>
        </template>   
    </LoggedIn>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import {useStore} from "vuex";
import LoggedIn from "./layouts/LoggedInWide.vue"
import CredentialList from "./../../../components/apps/common/credentials/CompactList.vue"
import Language from "./../../../constants/language/english.js";
import Run from 'run-sdk'
import { LocationMarkerIcon, UserIcon, ClockIcon,
BadgeCheckIcon,
UsersIcon,
CashIcon,
ReceiptRefundIcon,
AcademicCapIcon,
OfficeBuildingIcon} from '@heroicons/vue/solid'
import AccountSampleKeys from "./../../../constants/AccountSampleKeys.js";

const stats = [
  { label: 'Remaining Records', value: "1,254" },
  { label: 'Distributed', value: "1,600"},
  { label: 'via Aftercare', value: "1,988" },
]
const announcements = [
  {
    id: 1,
    title: 'Office closed on July 2nd',
    href: '#',
    preview:
      'Cum qui rem deleniti. Suscipit in dolor veritatis sequi aut. Vero ut earum quis deleniti. Ut a sunt eum cum ut repudiandae possimus. Nihil ex tempora neque cum consectetur dolores.',
  },
  {
    id: 2,
    title: 'New password policy',
    href: '#',
    preview:
      'Alias inventore ut autem optio voluptas et repellendus. Facere totam quaerat quam quo laudantium cumque eaque excepturi vel. Accusamus maxime ipsam reprehenderit rerum id repellendus rerum. Culpa cum vel natus. Est sit autem mollitia.',
  },
  {
    id: 3,
    title: 'Office closed on July 2nd',
    href: '#',
    preview:
      'Tenetur libero voluptatem rerum occaecati qui est molestiae exercitationem. Voluptate quisquam iure assumenda consequatur ex et recusandae. Alias consectetur voluptatibus. Accusamus a ab dicta et. Consequatur quis dignissimos voluptatem nisi.',
  },
]

const actions = [


  {
    icon: ClockIcon,
    name: 'Request time off',
    href: '#',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    icon: BadgeCheckIcon,
    name: 'Benefits',
    href: '#',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    icon: UsersIcon,
    name: 'Schedule a one-on-one',
    href: '#',
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  { icon: CashIcon, name: 'Payroll', href: '#', iconForeground: 'text-yellow-700', iconBackground: 'bg-yellow-50' },
  {
    icon: ReceiptRefundIcon,
    name: 'Submit an expense',
    href: '#',
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    icon: AcademicCapIcon,
    name: 'Training',
    href: '#',
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
]
export default {
    components: {
        LoggedIn,
        CredentialList,
        UserIcon,
        LocationMarkerIcon,
        // ClockIcon,
        // BadgeCheckIcon,
        // UsersIcon,
        // CashIcon,
        // ReceiptRefundIcon,
        // AcademicCapIcon,
        OfficeBuildingIcon,
    },
    async setup () {
        let store = useStore();
        const practiceName = new ref( store.state.practiceName);
        const businessId = new ref( store.state.businessId);
        const streetAddress = new ref( store.state.practiceStreetAddress);
        const streetAddress2 = new ref( store.state.practiceStreetAddress2);
        const city = new ref( store.state.practiceCity);
        const stateProvince = new ref( store.state.practiceStateProvince);
        const zipPostalCode = new ref( store.state.practiceZipPostalCode);
        const country = new ref( store.state.practiceCountry);
        const fetchCreds = async (state) => {
            let _run = new Run({network:"test", trust: "*", purse: AccountSampleKeys.PAYMENT_PRIVATE_KEY, owner: store.state.providerPrivKey});
            return  await state.dispatch("fetchProviderCredentials", _run);
        }
        if(store.state.credentials.length === 0){
            let response = await fetchCreds(store);
            console.log(response);
        }
        const state = reactive({
        
        })
    
        return {
            store,
            stats,
            actions,
            announcements,
            practiceName,
            businessId,
            streetAddress,
            streetAddress2,
            city,
            stateProvince,
            zipPostalCode,
            country,
            Language,
            ...toRefs(state),
        }
    },
    computed:{
        router(){
            return this.$router;
        }
    },
    methods:{
        save(){
            let name = this.practiceName;
            let id = this.businessId;
            let address = this.streetAddress;
            let address2 = this.streetAddress2;
            let city = this.city;
            let state = this.stateProvince;
            let zip = this.zipPostalCode;
            let country = this.country;    
            this.store.dispatch("setPracticeDetails", {name, id, address, address2, city, state, zip, country})
            alert("saved!")
        },
        clear(){
            this.$store.dispatch("setPracticeDetails", "","","","","","","","");
        }
    }
}
</script>

<style lang="scss" scoped>

</style>