<template>
   <purchase />
</template>

<script>
import { reactive, toRefs } from 'vue'
import Purchase from '../../../components/apps/lite/Purchase.vue'


export default {
  components: { Purchase },

     setup () {
      const state = reactive({
          count: 0
        })
        return {
            loading:false,
            ...toRefs(state),
        }
    },
    methods:{
       
    }
}
</script>

<style lang="scss" scoped>

</style>