import { createStore } from 'vuex';
import VuexPersist from "vuex-persist";
import ApplicationConstants from "./../constants/ApplicationConstants.js";
import VXConstants from './../constants/VXConstants.js'
import SampleKeys from "./../constants/AccountSampleKeys.js";
import Run from 'run-sdk';
import Utilities from './../helpers/Utilities.js';
import CredentialFactoryHelper from "./../helpers/CredentialFactoryHelper"
import account from "./../apis/account.js";
const vuexPersist = new VuexPersist({
  key: "vx-run-base-2",
  storage: window.localStorage
});


export default createStore({
  state: {
    adminSeed:"", 
    adminPrivKey:"",
    adminPaymentPrivateKey: "",
    adminAddress: "", 
    providerSeed: "",
    providerPaymentPrivateKey: "",
    providerPrivKey: "",
    patientSeed: "",
    patientFirstName: "", 
    patientLastName: "",
    patientMiddleInitial: "",
    patientBirthday: null, 
    patientGender: "none",
    patientRace: "none",
    patientStateID: "",
    patientPassportNumber: "",
    patient: "none",
    patientPrivKey: "",
    patientPaymentPrivateKey: "",
    providerAddress: "",
    providerBusinessAddress: "",
    providerBusinessAddress_2: "",
    providerCity:"",
    providerStateProvince:"",
    providerZip: "", 
    providerCountry: "",
    patientAddress: "",
    providerCredentialLocation: "",
    credFactories: [],
    selectedFactory: null,
    credentials: [],
    selectedCredential: null,
    practiceName: "",
    businessId: "",
    practiceStreetAddress: "",
    practiceStreetAddress2: "",
    practiceCity: "",
    practiceStateProvince: "",
    practiceZipPostalCode: "",
    practiceCountry: "",
    dose1Manufacturer: "",
    dose2Manufacturer: "",
    dose1LotID: "",
    dose2LotID: "",
    dose1DistributedDate: "",
    dose2DistributedDate: "",
    liteSeed: "",
    patientTokens: [],
    network:"", 

  },
  mutations: {
    setLiteSeed(state, seed){
      state.liteSeed = seed;
    },
    setAdminSeed(state, seed){
      state.adminSeed = seed; 
    },
    setAdminPrivKey(state, key){
      state.adminPrivKey = key; 
      console.log(state.adminPrivKey)
    },
    setAdminPaymentPrivateKey(state, key){
      state.adminPaymentPrivateKey = key;
    },
    setProviderSeed(state, seed){
      state.providerSeed = seed; 
    },
    setProviderPrivKey(state, key){
      state.providerPrivKey = key; 
    },
    setProviderPaymentPrivateKey(state, key){
      state.providerPaymentPrivateKey = key;
    },
    setPatientSeed(state, seed){
      state.patientSeed = seed; 
    },
    setPatientPrivKey(state, key){
      state.patientPrivKey = key; 
    },
    setPatientPaymentPrivateKey(state, key){
      state.patientPaymentPrivateKey = key;
    },
    setPatientFirstName(state, name){
      state.patientFirstName = name;
    },
    setPatientLastName(state, name){
      state.patientLastName = name;
    },
    setPatientMiddleInitial(state, i){
      state.patientMiddleInitial = i;
    },
    setPatientBirthday(state, birthday){
      state.patientBirthday = birthday;
    },
    setPatientStateID(state, stateID){
      
      state.patientStateID= stateID;
      console.log(state.patientStateID);
    },
    setPatientPassportNumber(state, passportNumber){
      console.log(passportNumber);
      state.patientPassportNumber = passportNumber;
    },
    setPatientGender(state, gender){
      state.patientGender = gender;
    },
    setPatientRace(state, race){
      state.patientRace = race;
    },
 
    setAdminAddress(state, address){
      state.adminAddress = address; 
    },
    setProviderAddress(state, address){
      state.providerAddress = address; 
    },
    setPatientAddress(state, address){
      state.patientAddress = address; 
    },
    setProviderCredentialLocation(state, _cl){
      state.providerCredentialLocation = _cl;
    },
    setMedicalCredentialFactories(state, _factories){
      console.log(state.credentialFactories);
      state.credFactories.push(_factories)
    },
    resetMedicalCredentialFactories(state){
      state.credFactories = [];
    },
    setSelectedFactory(state, _factory){
      state.selectedFactory = _factory;
      console.log(_factory)
    },
    resetCredentials(state) {
      state.credentials = [];
    },
    setAddCredentialToList(state, _creds) {
      state.credentials.push(_creds);
    },
    setSelectedCredential(state, _cred){
      state.selectedCredential = _cred;
    },
    setPracticeName(state, _name){
      state.practiceName = _name;
    },
    setBusinessId(state, _id){
      state.businessId = _id;
    },
    setPracticeStreetAddress(state, _address){
      state.practiceStreetAddress = _address;
    },
    setPracticeStreetAddress2(state, _address){
      state.practiceStreetAddress2 = _address;
    },
    setPracticeCity(state, _city){
      state.practiceCity = _city;
    },
    setPracticeStateProvince(state, _stateProvince){
      state.practiceStateProvince = _stateProvince;
    },
    setPracticeZipPostalCode(state, _zip){
      state.practiceZipPostalCode = _zip;
    },
    setPracticeCountry(state, _country){
      state.practiceCountry = _country;
    },
    setDose1Manufacturer(state, _mfg){
      state.dose1Manufacturer = _mfg;
    },
    setDose2Manufacturer(state, _mfg){
      state.dose2Manufacturer = _mfg;
    },
    setDose1LotID(state, _lotId){
      state.dose1LotID = _lotId;
    },
    setDose2LotID(state, _lotId){
      state.dose2LotID = _lotId;
    },
    setDose1DistributedDate(state, _date){
      state.dose1DistributedDate = _date;
    },
    setDose2DistributedDate(state, _date){
      state.dose2DistributedDate = _date;
    },
    resetPatientTokens(state){
      state.patientTokens = [];
    },
    setPatientTokens(state, _tokens){
      _tokens.forEach(t => state.patientTokens.push(t))
    },
    setNetwork(state, _net){
      state.network = _net;
      console.log("Network Set To: ", _net)
    }
  },
  actions: {
    async login({commit}, {_seed, _loginType}){
      console.log("Logging In with seed: ", _seed, " and type: ", _loginType)
      let response = "";
      let paymentpk = "";
      let ownerpk = "";
      let address = "";
      let network = "testnet";
      switch(_loginType){
        case ApplicationConstants.LOGIN_TYPE_ADMIN: 
           console.log("LOGIN_TYPE_ADMIN");
           ownerpk = account.privateKeyFromSeed(_seed, ApplicationConstants.ADMIN_OWNER_PATH, network)
           paymentpk = account.privateKeyFromSeed(_seed, ApplicationConstants.ADMIN_PAYMENT_PATH, network);
           address = account.addressFromSeed(_seed, ApplicationConstants.ADMIN_OWNER_PATH, network);
           if(!(ownerpk instanceof Error) && !(paymentpk instanceof Error)){
             commit("setAdminSeed", _seed);
             commit("setAdminPrivKey", ownerpk); 
             commit("setAdminAddress", address);
             commit("setAdminPaymentPrivateKey", paymentpk); 
             response = "Success " + paymentpk.toString() + " "  + ownerpk.toString()
           }else{ response = new Error(ownerpk, paymentpk)}
          break;
        case ApplicationConstants.LOGIN_TYPE_PROVIDER:
          ownerpk = account.privateKeyFromSeed(_seed, ApplicationConstants.PROVIDER_OWNER_PATH, network)
          paymentpk = account.privateKeyFromSeed(_seed, ApplicationConstants.PROVIDER_PAYMENT_PATH, network);
          address = account.addressFromSeed(_seed, ApplicationConstants.PROVIDER_OWNER_PATH, network);
          console.log("LOGIN_TYPE_PROVIDER",_seed, ApplicationConstants.PATIENT_OWNER_PATH, network);
          if(!(ownerpk instanceof Error) && !(paymentpk instanceof Error)){
            console.log("committing provider details");
            commit("setProviderSeed", _seed);
            commit("setProviderPrivKey", ownerpk); 
            commit("setProviderPaymentPrivateKey", paymentpk); 
            commit("setProviderAddress", address);
            response = "Success " + paymentpk.toString() + " "  + ownerpk.toString()
          }else{ response = new Error(ownerpk, paymentpk)}
          break;
        case ApplicationConstants.LOGIN_TYPE_PATIENT:
          ownerpk = account.privateKeyFromSeed(_seed, ApplicationConstants.PATIENT_OWNER_PATH, network)
          paymentpk = account.privateKeyFromSeed(_seed, ApplicationConstants.PATIENT_PAYMENT_PATH, network);
          address = account.addressFromSeed(_seed, ApplicationConstants.PATIENT_OWNER_PATH, network);
          console.log("LOGIN_TYPE_PATIENT", _seed, ApplicationConstants.PATIENT_OWNER_PATH, network);
          if(!(ownerpk instanceof Error) && !(paymentpk instanceof Error)){
            commit("setPatientSeed", _seed);
            commit("setPatientPrivKey", ownerpk); 
            commit("setPatientPaymentPrivateKey", paymentpk); 
            commit("setPatientAddress", address);
            response = "Success " + paymentpk.toString() + " "  + ownerpk.toString()
          }else{ response = new Error(ownerpk, paymentpk)}
          break;
        default: 
          break;
      }
      return response;
    },
    async createAccount({commit}, _type){
      let run;
      switch(_type){
        case "admin":
          run = new Run({network: "test", trust: "*", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: SampleKeys.ADMIN_PRIVATE_KEY });
          commit("setAdminPrivKey", run.owner.privkey);
          commit("setAdminAddress", run.owner.address);
          break;
        case "provider":
          run = new Run({network: "test", trust: "*", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: SampleKeys.PROVIDER_PRIVATE_KEY });
          commit("setProviderPrivKey", run.owner.privkey);
          commit("setProviderAddress", run.owner.address);
          break; 
        case "patient":
          break;
      }

    },

    async createCredentialFactory({commit, state}, details){
      let _run = new Run({network: "test", trust: "*", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: state.adminPrivKey });
       let factory = await CredentialFactoryHelper.createFactory(_run, details.country, details.state, details.organization, details.description, details.url, details.logoUrl)
      if(factory){
        commit("setSelectedFactory", factory)
      }
    },

    async fetchCredentialFactories({commit}, _run, _sync){
      commit("resetMedicalCredentialFactories");
      await _run.activate();
      console.log(_sync);
        //if((_run) && (_sync || _run.inventory.jigs.length === 0)){
          await _run.inventory.sync();
        //}
        if(_run.inventory.jigs.length === 0){console.log("No Jigs are from the original cred factory"); return;}
        console.log("Jigs returned:", _run.inventory.jigs);
        _run.inventory.jigs.forEach((j) => {
          console.log("contructor origin:", j.constructor.origin, j.organization);
          if(j.constructor.origin === VXConstants.MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN){
            commit("setMedicalCredentialFactories", j);
          } 
        })
        console.log(_run.inventory.jigs)
        
        
    },

    async loadSelectedFactory({commit, state}, _location){
      let _run = new Run({network: "test", trust: "*", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: state.adminPrivKey });
      let _factory = await _run.load(_location)
      await _factory.sync()
      commit("setSelectedFactory", _factory);
      return _factory; 
    },

    async addCredentialToFactory({commit, state}, id, address){
      try{
        console.log(address);
        let _run = new Run({network: "test", trust: "*", purse: SampleKeys.PAYMENT_PRIVATE_KEY, owner: state.adminPrivKey });
        let _f = await _run.load(state.selectedFactory.location);
        await _f.createCredentials(address, id, Utilities.dateString(0))
        await _f.sync()
        commit("setSelectedFactory", _f)
      }catch(err){console.log(err)}
    },

    async fetchProviderCredentials({commit}, _run) {
        commit("resetCredentials");
        _run.activate();
        await _run.inventory.sync();
        console.log(_run.owner.address);
        console.log(_run.inventory.jigs);
        if(_run.inventory.jigs.length === 0){console.log("There are no jigs in your wallet"); return;}
        _run.inventory.jigs.forEach((j) => {
          if(j.constructor.origin === VXConstants.CREDENTIAL_CLASS_LOCATION){
            commit("setAddCredentialToList", j);
          }
        });

    },
    //TODO: Add Methods for Provider Add Single Record 
    //TODO: Add Methods for Provider Add Multiple Records 
    //TODO  Add Method to Query The Blockchain for an account & set the Patient tokens  
    //TODO: Add Response to Scan Patient Data 
    //TODO: Add Response to Scan Provider Login QR 
    updatePatientDetails({commit}, details) {
      console.log(details)
      if(details.address){console.log(details.address); commit("setPatientAddress", details.address)}
      if(details.firstName){console.log(details.firstName); commit("setPatientFirstName", details.firstName)}
      if(details.lastName){commit("setPatientLastName", details.lastName)}
      if(details.middleInitial){commit("setPatientMiddleInitial", details.middleInitial)}
      if(details.birthday){commit("setPatientBirthday", details.birthday)}
      if(details.gender){commit("setPatientGender", details.gender)}
      if(details.race){commit("setPatientRace", details.race)}
      if(details.stateID){commit("setPatientStateID", details.stateID)}
      if(details.passportNumber){console.log(details.passportNumber);commit("setPatientPassportNumber", details.passportNumber)}
      return true;
    },

    clearPatientPersonalData({commit}){
      commit("setPatientAddress",  ""); 
      commit("setPatientFirstName",  ""); 
      commit("setPatientLastName",  ""); 
      commit("setPatientMiddleInitial",  ""); 
      commit("setPatientBirthday",  ""); 
      commit("setPatientGender",  ""); 
      commit("setPatientRace", ""); 
      commit("setPatientStateID",  ""); 
      commit("setPatientPassportNumber", ""); 
      return true;
    },
    clearPatientDoseData({commit}){
      commit("setDose1LotID", "")
      commit("setDose2LotID", "")
      commit("setDose1DistributedDate", "")
      commit("setDose2DistributedDate", "")
      commit("setDose1Manufacturer", "")
      commit("setDose2Manufacturer", "")
    },
    async setPracticeDetails({commit}, {name, id, address, address2, city, state, zip, country}){
      console.log(name, id, address, address2, city, state, zip, country);
      commit("setPracticeName", name);
      commit("setBusinessId", id);
      commit("setPracticeStreetAddress", address);
      commit("setPracticeStreetAddress2", address2);
      commit("setPracticeCity", city);
      commit("setPracticeStateProvince", state);
      commit("setPracticeZipPostalCode", zip);
      commit("setPracticeCountry", country);
      console.log(name, id, address, address2, city, state, zip, country);
    },

    clearProviderAccount({commit}){
      commit("setProviderAddress", "");
      commit("setProviderSeed", "");
      commit("setProviderPrivKey", "");
      commit("setProviderCredentialLocation", "");
      commit("setSelectedCredential", null);
      
    },

    clearPracticeDetails({commit}){
      commit("setPracticeName", "");
      commit("setBusinessId", "");
      commit("setPracticeStreetAddress", "");
      commit("setPracticeStreetAddress2", "");
      commit("setPracticeCity", "");
      commit("setPracticeStateProvince", "");
      commit("setPracticeZipPostalCode", "");
      commit("setPracticeCountry", "");
      commit("resetCredentials");
    }
  },
  modules: {
  },
  plugins: [vuexPersist.plugin]
})
