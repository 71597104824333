<template>
    
        <simple-brand-contact 
        headText="Check If A Record is" 
        subHeadText="VXPASS uses self verifying credentials. This means you only need a patient's address (ID) or the transaction ID of a record in order to validate it."
        leftTitleText= "Self Verifying Records"
        leftSubTitleText="There is no call to a VXPASS service. Each record contains all the vaccination data &amp; a signature from the doctor who made the record. If any of the information used to check the doctors signature is modified by any party, the record is considered invalid."
        rightTitleText="Search for VXVerified Records By Address or QR Code">
            <template v-slot:leftSection> 
                <div class="center stream">
                    <qr-stream @decode="onDecode" class="mb">
                        <div style="color: red;" class="frame"></div>
                    </qr-stream>
                    </div>
                    <qr-capture @decode="onDecode" class="mb p-4 m-4 shadow"></qr-capture>
                    <div class="result text-white">
                    Scanned: 
                    <div> 
                        {{data ? data["firstName"] : "" }} {{data ? data["middleInitial"] : "" }} {{data ? data["lastName"] : "" }}
                    </div>
                    <div> 
                        {{formedBirthday}}
                    </div>
                    </div>
            </template>
            <template v-slot:formHolder >
                <div  class="m-6 p-6 grid grid-cols-3 gap-y-6 sm:grid-cols-3 sm:gap-x-8 shadow">
                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
                        <input v-model="firstName" type="text" name="firstName" id="firstName" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="middleInitial" class="block text-sm font-medium text-gray-700">Middle</label>
                        <input v-model="middleInitial" type="text" name="middleInitial" id="middleInitial" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <label for="lastName" class="block text-sm font-medium text-gray-700">LastName</label>
                        <input v-model="lastName" type="text" name="lastName" id="lastName" autocomplete="postal-code" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>
                     <div class="flex rounded-md shadow-sm w-full col-span-3 lg:col-span-3 sm:col-span-2 ">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm sm:px-1">
                            BSV Address:
                        </span>
                        <input v-model="address" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                    </div>
                    <div class="sm:col-span-2 sm:flex sm:justify-end w-full">
                    <button @click="checkForTokensToVerify" type="" class="mt-2 h-12 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto">
                        Submit
                    </button>
                    </div>
                    
                </div>
                <div class="pt-4 mt-4"> 
                    <div class='text-xl text-left p-2 m-2'> {{message}}</div>
                        <ol> 
                            <li v-for="token in tokens" :key="token.location">
                                <div v-if="checkVerify(token)" class='shadow rounded-xl'> 
                                    <div class="flex">
                                        <div class='flex flex-1 p-2 m-2'> 
                                            <CheckCircleIcon class="max-h-12 max-w-12 text-green-400"/>  
                                            <div class="flex flex-col">
                                            <div class="text-left"> {{token.mfg}} </div>
                                            <div> {{new Date(token.distributed).toLocaleDateString("en-US")}} </div>
                                            </div>
                                        </div>
                                        <div class='flex flex-1 p-2 m-2'> 
                                            <div v-if="checkVerifySig(token)" > 
                                                <div class="flex flex-col">
                                                    <div class='flex flex-1 items-center justify-center'> 
                                                        <CheckCircleIcon class="max-h-12 max-w-12 text-green-400"/>  
                                                        <div> Verified Name</div>
                                                    </div>
                                                </div>
                                                <div class='flex flex-1'> 
                                                    
                                                </div>
                                            </div>
                                            <div v-else class='text-red-800'> 
                                                This name does not match the record
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else> 
                                    <div class='text-red-800'> 
                                        This Record was modified from its original form
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div> 
                    
                    
            </template>
            <template v-slot:footer >
                <div> </div>
            </template>
        </simple-brand-contact>
    
</template>

<script>
import { reactive, toRefs } from 'vue'
import SimpleBrandContact from "./../../../tailwind/pages/SimpleBrandContact.vue"
import Run from "run-sdk";
import VXConstants from "./../../../../constants/VXConstants.js";
import Utilities from "./../../../../helpers/Utilities.js"
import {CheckCircleIcon} from "@heroicons/vue/outline"
import { QrCapture } from "vue3-qr-reader";

export default {
    setup () {
        const onDecode = async(data) => {
            console.log(data.toString());
            if(data !== "<empty string>"){
                state.data = JSON.parse(data);
                if(state.data["firstName"]){state.firstName = state.data["firstName"]}
                if(state.data["middleInitial"]){state.middleInitial = state.data["middleInitial"]}
                if(state.data["lastName"]){state.lastName = state.data["lastName"]}
                if(state.data["address"]){state.address = state.data["address"]}
                await checkForTokensToVerify();
            }
        }
        const checkForTokensToVerify = async() =>{
            state.message = "Checking For Tokens..."
            state.tokens.value = [];
            console.log(state);
            if(state.address === ""){return alert("An ID (address) is required")}
            const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })
            let _run =  new Run({blockchain: blockchain, network:"test", trust:'*', owner: state.address})
            console.log(_run.owner.address);
            
            await _run.inventory.sync();
            console.log(_run);
            
            let _jigs = _run.inventory.jigs;
            console.log({_jigs})
            _jigs.forEach( jig => {
                if(jig.cred && jig.cred.constructor.origin === VXConstants.CREDENTIAL_CLASS_ORIGIN){
                    console.log("This is a VXRecord");
                    //state.tokens.push( jig)
                }
            });
            let _tokens = await Utilities.findRecords(_run)
            _tokens.forEach((t) => state.tokens.push(t))
            state.message = "VXPASS Records:";
            if(state.tokens.length === 0 ){state.message = "There are no VXPASS Records to Verify"}
        }
        const state = reactive({
            firstName: "",
            middleInitial: "",
            lastName: "",
            birthday: "",
            address: "",
            tokens: [],
            message: "",
            data: null
        })
    
        return {
            ...toRefs(state),
            onDecode,
            checkForTokensToVerify
        }
    },
    methods:{
        
        checkVerify(_token){
            return Utilities.verifyRecordSig(_token);
        },
         checkVerifySig(_token){
            return Utilities.verifyRecordSigwName(_token, this.firstName, this.middleInitial, this.lastName);
        }
    },
    computed:{
        formedBirthday(){
            if(!this.data)return "";
            if (!this.data.birthday) return "";
            let dateArr = this.data.birthday.split("-");
            return `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
        }
    },
    components:{
        SimpleBrandContact,
        CheckCircleIcon,
        QrCapture
    }
}
////muYnNptJ9F5YY6j8xtFy8aMAed1jxKH
</script>

<style  scoped>
.stream {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
</style>
